const state = {
  dataTableOptions: {
    groupBy: [],
    groupDesc: [],
    itemsPerPage: 25,
    multiSort: true,
    mustSort: false,
    page: 1,
    sortBy: ["node.createdAt"],
    sortDesc: [true],
  },
  search: null,
};

const mutations = {
  SET_DATA_TABLE_OPTIONS(state, payload) {
    state.dataTableOptions = payload;
  },

  SET_SEARCH(state, payload) {
    state.search = payload;
  },
};

const actions = {
  setDataTableOptions(context, payload) {
    context.commit("SET_DATA_TABLE_OPTIONS", payload);
  },

  setSearch(context, payload) {
    context.commit("SET_SEARCH", payload);
  },
};

const getters = {
  getDataTableOptions(state) {
    return state.dataTableOptions;
  },

  getSearch(state) {
    return state.search;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
