<template>
  <v-container fluid>
    <v-row class="align-center pl-3">
      <v-breadcrumbs :items="breadcrumbs" divider="/"></v-breadcrumbs>
      <v-spacer />
    </v-row>

    <v-container :fluid="$vuetify.breakpoint.lgAndDown">
      <v-row justify="center" align="center">
        <v-col cols="12">
          <v-tabs v-model="activeTab" @change="updateActiveTab">
            <v-tabs-slider color="primary"></v-tabs-slider>

            <v-tab v-for="(tab, index) in tabs" :key="index">
              {{ tab.label }}
            </v-tab>

            <v-spacer />

            <v-btn
              :disabled="!isDeleteButtonEnabled"
              @click="deleteSelected"
              class="mt-2"
              color="error"
              dark
              outlined
              rounded
              small
              v-if="showButtons"
            >
              {{ $t("button.deleteBtn") }}
            </v-btn>

            <v-btn
              @click="add"
              class="mt-2 ml-2"
              color="primary"
              dark
              outlined
              rounded
              small
              v-if="showButtons"
            >
              {{ $t("button.addBtn") }}
            </v-btn>
          </v-tabs>
          <v-divider></v-divider>

          <v-tabs-items v-model="activeTab">
            <v-tab-item v-for="(tab, index) in tabs" :key="index">
              <component
                :is="tab.component"
                :ref="tab.name"
                v-on:updateNrOfSelectedItems="
                  onUpdateNrOfSelectedItems(index, $event)
                "
              />
            </v-tab-item>
          </v-tabs-items>
        </v-col>
      </v-row>
    </v-container>
  </v-container>
</template>

<script>
export default {
  name: "base-tabs",
  components: {},
  props: {
    breadcrumbs: {
      type: Array,
      required: true,
    },
    tabs: {
      type: Array,
      required: true,
      validator: (value) => {
        return value.every((tab) => {
          return tab.label && tab.component;
        });
      },
    },
    tab: {
      type: Number,
      required: true,
    },
  },

  data() {
    return {
      activeTab: this.tab,
      selectedItemsCount: [],
    };
  },
  computed: {
    isDeleteButtonEnabled() {
      return this.selectedItemsCount[this.activeTab] > 0;
    },

    showButtons() {
      const activeTab = this.tabs[this.activeTab];
      // Default to true if showButtons is undefined
      return activeTab?.showButtons ?? true;
    },
  },
  watch: {
    tab(newVal) {
      this.activeTab = newVal;
    },
  },
  created() {},
  methods: {
    add() {
      const activeComponent = this.getActiveComponentRef();
      if (activeComponent && activeComponent.add) {
        activeComponent.add();
      }
    },

    deleteSelected() {
      const activeComponent = this.getActiveComponentRef();
      if (activeComponent && activeComponent.deleteSelected) {
        activeComponent.deleteSelected();
      }
    },

    getActiveComponentRef() {
      const activeTab = this.tabs[this.activeTab];
      const componentRef = this.$refs[activeTab.name];
      return componentRef && componentRef[0] ? componentRef[0] : null;
    },

    onUpdateNrOfSelectedItems(index, length) {
      this.$set(this.selectedItemsCount, index, length);
    },

    updateActiveTab(index) {
      this.$emit("updateTab", index);
    },
  },
};
</script>
