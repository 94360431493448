<template>
  <v-container fluid class="pa-0">
    <!-- loading status -->
    <v-row class="ma-0 pa-0">
      <v-progress-linear
        v-if="$apollo.queries.dashboardQuery.loading"
        color="primary"
        indeterminate
        rounded
        class="ma-0 pa-0"
      ></v-progress-linear>
    </v-row>

    <v-container>
      <v-row justify="center" align="center" class="mt-10">
        <v-col cols="12" sm="6">
          <v-autocomplete
            :filter="customFilter"
            :items="searchReports?.edges"
            :loading="$apollo.queries.searchReports.loading"
            :search-input.sync="search"
            @change="
              goToReportDetail(
                selectedReport.node.id,
                selectedReport.node.reportType
              )
            "
            cache-items
            clearable
            hide-details
            hide-no-data
            item-text="node.name"
            item-value="node.id"
            prepend-inner-icon="search"
            return-object
            solo
            v-bind:placeholder="$t('home.searchReportLabel')"
            v-model="selectedReport"
          >
            <template slot="item" slot-scope="{ item }">
              <v-icon
                :color="item.node.isStandard ? 'warning' : 'primary'"
                class="mr-2"
                >{{
                  item.node.reportType == "POWERBIREPORT"
                    ? "dashboard"
                    : "layers"
                }}</v-icon
              >
              {{
                `${item.node?.tenant?.tenantName} - ${item.node?.reportName}`
              }}
            </template>
          </v-autocomplete>
        </v-col>
        <v-col cols="12" sm="2" v-if="me.isSuperuser">
          <v-switch
            v-model="underMaintenance.isUnderMaintenance"
            @change="updateUnderMaintenance"
            :loading="isLoadingUnderMaintenance"
            :disabled="isLoadingUnderMaintenance"
            v-bind:label="$t('home.underMaintenanceLabel')"
            class="my-0 py-0"
            hide-details
          ></v-switch>
        </v-col>
      </v-row>

      <v-row align="center" class="mt-10">
        <!-- #Reports -->
        <v-col v-if="dashboardQuery.reportsTotalCount" cols="12" sm="6" md="4">
          <v-hover>
            <template v-slot="{ hover }">
              <v-card
                :loading="
                  $apollo.queries.dashboardQuery.loading ? 'white' : null
                "
                :elevation="hover ? 10 : 1"
                color="primary lighten-1"
                dark
                outlined
                height="160px"
                link
                href="/#/reports"
              >
                <v-card-title class="subtitle-1">{{
                  $t("home.reportsTitle")
                }}</v-card-title>

                <v-card-text>
                  <v-row align="center">
                    <v-col cols="2">
                      <v-icon large>bar_chart</v-icon>
                    </v-col>
                    <v-col cols="10" class="display-3">{{
                      dashboardQuery.reportsTotalCount || 0
                    }}</v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </template>
          </v-hover>
        </v-col>

        <!-- #Knowledgebase -->
        <v-col
          v-if="dashboardQuery.knowledgeBaseTotalCount"
          cols="12"
          sm="6"
          md="4"
        >
          <v-hover>
            <template v-slot="{ hover }">
              <v-card
                :loading="
                  $apollo.queries.dashboardQuery.loading ? 'white' : null
                "
                :elevation="hover ? 10 : 1"
                color="primary lighten-1"
                dark
                outlined
                height="160px"
                link
                href="/#/knowledge-base"
              >
                <v-card-title class="subtitle-1">{{
                  $t("home.knowledgeBaseTitle")
                }}</v-card-title>

                <v-card-text>
                  <v-row align="center">
                    <v-col cols="2">
                      <v-icon large>info</v-icon>
                    </v-col>
                    <v-col cols="10" class="display-3">{{
                      dashboardQuery.knowledgeBaseTotalCount || 0
                    }}</v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </template>
          </v-hover>
        </v-col>

        <!-- My Profile -->
        <v-col cols="12" sm="6" md="4">
          <v-hover>
            <template v-slot="{ hover }">
              <v-card
                :loading="
                  $apollo.queries.dashboardQuery.loading ? 'white' : null
                "
                :elevation="hover ? 10 : 1"
                :color="!me.passed2fa ? 'error' : 'primary lighten-1'"
                dark
                outlined
                height="160px"
                link
                href="/#/settings"
              >
                <v-card-title class="subtitle-1"
                  >{{ $t("home.settingsTitle") }}
                  <v-spacer></v-spacer>
                  <v-icon v-if="!me.passed2fa" left>warning</v-icon>
                </v-card-title>

                <v-card-text>
                  <v-row align="center">
                    <v-col cols="2">
                      <v-icon large>settings</v-icon>
                    </v-col>
                    <v-col cols="10" class="display-2">
                      {{ me.firstName }}</v-col
                    >
                  </v-row>
                </v-card-text>
              </v-card>
            </template>
          </v-hover>
        </v-col>

        <!-- #Users -->
        <v-col
          v-if="
            hasPermission('users.view_customuser') &&
            dashboardQuery.usersTotalCount
          "
          cols="12"
          sm="6"
          md="3"
        >
          <v-hover>
            <template v-slot="{ hover }">
              <v-card
                :loading="
                  $apollo.queries.dashboardQuery.loading ? 'white' : null
                "
                :elevation="hover ? 10 : 1"
                color="primary lighten-1"
                dark
                outlined
                height="160px"
                link
                href="/#/users"
              >
                <v-card-title class="subtitle-1">{{
                  $t("home.usersTitle")
                }}</v-card-title>

                <v-card-text>
                  <v-row align="center">
                    <v-col cols="2">
                      <v-icon large>contacts</v-icon>
                    </v-col>
                    <v-col cols="10" class="display-3">{{
                      dashboardQuery.usersTotalCount || 0
                    }}</v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </template>
          </v-hover>
        </v-col>

        <!-- Status -->
        <v-col v-if="dashboardQuery.overallStatus" cols="12" sm="6" md="5">
          <v-hover>
            <template v-slot="{ hover }">
              <v-card
                :loading="
                  $apollo.queries.dashboardQuery.loading ? 'white' : null
                "
                :elevation="hover ? 10 : 1"
                :color="dashboardQuery.overallStatus.node.severity.color"
                dark
                outlined
                height="160px"
                link
                href="/#/status"
              >
                <v-card-title class="subtitle-1">{{
                  $t("home.statusTitle")
                }}</v-card-title>

                <v-card-text>
                  <v-row align="center">
                    <v-col cols="2">
                      <v-icon large>{{
                        dashboardQuery.overallStatus.node.severity.icon
                      }}</v-icon>
                    </v-col>
                    <v-col cols="10" class="display-1">{{
                      dashboardQuery.overallStatus.node.severity.severityName
                    }}</v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </template>
          </v-hover>
        </v-col>

        <!-- #Announcements -->
        <v-col cols="12" sm="6" md="4">
          <v-hover>
            <template v-slot="{ hover }">
              <v-card
                :loading="
                  $apollo.queries.dashboardQuery.loading ? 'white' : null
                "
                :elevation="hover ? 10 : 1"
                color="#EC6726"
                dark
                outlined
                height="160px"
                link
                href="/#/announcements"
              >
                <v-card-title class="subtitle-1">{{
                  $t("home.announcementTitle")
                }}</v-card-title>

                <v-card-text>
                  <v-row align="center">
                    <v-col cols="2">
                      <v-icon large>lightbulb</v-icon>
                    </v-col>
                    <v-col cols="10" class="display-1">{{
                      $t("announcements.announcementShort")
                    }}</v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </template>
          </v-hover>
        </v-col>

        <!-- #Maintenance -->
        <v-col v-if="dashboardQuery.maintenance" cols="12" sm="6" md="4">
          <v-hover>
            <template v-slot="{ hover }">
              <v-card
                :loading="
                  $apollo.queries.dashboardQuery.loading ? 'white' : null
                "
                :elevation="hover ? 10 : 1"
                color="primary lighten-1"
                dark
                outlined
                height="160px"
                link
                href="/#/status"
              >
                <v-card-title class="subtitle-1">{{
                  $t("home.nextMaintenanceTitle")
                }}</v-card-title>

                <v-card-text>
                  <v-row align="center">
                    <v-col cols="2">
                      <v-icon large>build</v-icon>
                    </v-col>
                    <v-col cols="10" class="display-2">{{
                      dashboardQuery.maintenance.node.start
                        | moment("YYYY-MM-DD")
                    }}</v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </template>
          </v-hover>
        </v-col>
      </v-row>

      <!-- favorite reports -->
      <v-row class="mt-10">
        <v-slide-group active-class="success" show-arrows>
          <v-slide-item
            v-for="reportFavorite in reportFavorites.edges"
            :key="reportFavorite.node.id"
          >
            <v-hover>
              <template v-slot="{ hover }">
                <v-card
                  :loading="
                    $apollo.queries.reportFavorites.loading ? 'white' : null
                  "
                  :elevation="hover ? 10 : 1"
                  color="grey lighten-1"
                  dark
                  outlined
                  height="160px"
                  link
                  class="ma-4"
                >
                  <v-card-title class="subtitle-1"
                    >{{ reportFavorite.node.report.reportName
                    }}<v-spacer></v-spacer>
                    <v-icon
                      right
                      small
                      @click="deleteReportFavorite(reportFavorite.node.id)"
                      >star</v-icon
                    >
                  </v-card-title>

                  <v-card-text
                    @click="
                      goToReportDetail(
                        reportFavorite.node.report.id,
                        reportFavorite.node.report.reportType
                      )
                    "
                    class="text-center"
                  >
                    <v-row>
                      <v-col cols="12">
                        <v-icon
                          v-if="
                            reportFavorite.node.report.reportType ===
                            'POWERBIREPORT'
                          "
                          x-large
                          >dashboard</v-icon
                        >
                        <v-icon
                          v-else-if="
                            reportFavorite.node.report.reportType ===
                            'PAGINATEDREPORT'
                          "
                          x-large
                          >layers</v-icon
                        >
                      </v-col>
                      <v-col cols="12">
                        <v-chip
                          small
                          v-if="
                            reportFavorite.node.report.lastRefreshStatus ===
                            'Completed'
                          "
                          color="success"
                          >{{
                            reportFavorite.node.report.lastRefreshEndTime
                              | moment("YYYY-MM-DD HH:mm")
                          }}</v-chip
                        >
                        <v-chip
                          v-else-if="
                            reportFavorite.node.report.lastRefreshStatus ===
                            'Failed'
                          "
                          color="error"
                          >{{
                            reportFavorite.node.report.lastRefreshEndTime
                              | moment("YYYY-MM-DD HH:mm")
                          }}</v-chip
                        >
                        <v-chip
                          v-else-if="
                            reportFavorite.node.report.lastRefreshStatus ===
                            'Disabled'
                          "
                          color="error"
                          >{{
                            reportFavorite.node.report.lastRefreshEndTime
                              | moment("YYYY-MM-DD HH:mm")
                          }}</v-chip
                        >
                        <v-chip
                          v-else-if="
                            reportFavorite.node.report.lastRefreshStatus ===
                            'Unknown'
                          "
                          color="warning"
                          >{{
                            reportFavorite.node.report.lastRefreshEndTime
                              | moment("YYYY-MM-DD HH:mm")
                          }}</v-chip
                        >
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-card>
              </template>
            </v-hover>
          </v-slide-item>
        </v-slide-group>
      </v-row>
    </v-container>
  </v-container>
</template>

<script>
import gql from "graphql-tag";
import helper from "@/utils/helper.js";

export default {
  props: ["loggedOutAsOtherUserCounter"],
  apollo: {
    dashboardQuery: {
      query: gql`
        query dashboardQuery($allReports: Boolean!) {
          reports(allReports: $allReports) {
            totalCount
          }
          knowledgeBases {
            totalCount
          }
          systems(first: 1) {
            edges {
              node {
                severity {
                  icon
                  color
                  severityName
                }
              }
            }
          }
          maintenances(first: 1) {
            edges {
              node {
                id
                maintenanceName
                start
                end
              }
            }
          }
          users {
            totalCount
          }
        }
      `,
      variables() {
        return {
          allReports: false,
        };
      },
      fetchPolicy: "network-only",
      update: (data) => {
        return {
          reportsTotalCount: data.reports.totalCount,
          knowledgeBaseTotalCount: data.knowledgeBases.totalCount,
          overallStatus: data.systems.edges[0],
          maintenance: data.maintenances.edges[0],
          usersTotalCount: data.users.totalCount,
          reports: data.reports.edges,
        };
      },
    },

    reportFavorites: {
      query: gql`
        query reportFavorites {
          reportFavorites {
            edges {
              node {
                id
                isFavorite
                report {
                  id
                  reportName
                  lastRefreshEndTime
                  lastRefreshStatus
                  reportType
                }
              }
            }
          }
        }
      `,
      variables() {},
      fetchPolicy: "network-only",
      update: (data) => data.reportFavorites,
    },

    underMaintenance: {
      query: gql`
        query underMaintenance {
          underMaintenance(id: "VW5kZXJNYWludGVuYW5jZU5vZGU6MQ==") {
            id
            createdAt
            modifiedAt
            isUnderMaintenance
          }
        }
      `,
      variables() {},
      fetchPolicy: "network-only",
      update: (data) => {
        return {
          isUnderMaintenance: data.underMaintenance.isUnderMaintenance,
        };
      },
      pollInterval: 60000, // ms
    },

    searchReports: {
      query: gql`
        query searchReports(
          $search: String
          $orderBy: [String]
          $allReports: Boolean!
        ) {
          reports(search: $search, orderBy: $orderBy, allReports: $allReports) {
            edges {
              node {
                id
                reportName
                reportType
                tenant {
                  tenantName
                }
              }
            }
          }
        }
      `,
      fetchPolicy: "cache-and-network",
      update: (data) => data.reports,
      skip: true,
    },
  },

  data() {
    return {
      search: null,
      isLoadingSearch: false,
      selectedReport: {},
      isLoadingUnderMaintenance: false,

      dashboardQuery: {},
      reportFavorites: {},
      underMaintenance: {},
      searchQuery: {
        reports: [],
      },

      searchReports: {},
    };
  },

  computed: {
    me() {
      return this.$store.state.user.me || {};
    },

    isLoggedInAsOtherUser() {
      return this.$store.state.user.loggedInAsOtherUser;
    },

    customFilter() {
      return (item, queryText, itemText) => {
        const searchText = queryText.toLowerCase();
        const name = item.node.reportName.toLowerCase();
        return name.includes(searchText);
      };
    },
  },
  watch: {
    search(val) {
      if (this.search == null || this.search.length < 1) {
        return;
      } else {
        this.refresh();
      }
    },

    loggedOutAsOtherUserCounter(val) {
      this.refreshCurrentPage();
    },

    isLoggedInAsOtherUser(val) {
      if (val) {
        this.refreshCurrentPage();
      }
    },
  },
  created() {
    this.hasPermission = helper.hasPermission;
  },
  methods: {
    refresh() {
      this.$apollo.queries.searchReports.setVariables({
        search: this.search,
        orderBy: ["reportName"],
        allReports: true,
      });

      this.$apollo.queries.searchReports.skip = false;
      this.$apollo.queries.searchReports.refresh();
    },

    refreshCurrentPage() {
      this.$apollo.queries.dashboardQuery.refresh();
      this.$apollo.queries.reportFavorites.refresh();
    },

    goToReportDetail(reportNodeId, reportType) {
      if (reportType === "POWERBIREPORT") {
        this.$router.push({
          name: "report-detail",
          params: {
            reportNodeId: reportNodeId,
          },
        });
      } else if (reportType === "PAGINATEDREPORT") {
        this.$router.push({
          name: "paginated-report-detail",
          params: {
            reportNodeId: reportNodeId,
          },
        });
      }
    },

    deleteReportFavorite(reportFavoriteId) {
      // prepare api call payload
      var payload = {
        id: reportFavoriteId,
      };

      this.$apollo
        .mutate({
          // Query
          mutation: gql`
            mutation deleteReportFavorite($input: DeleteReportFavoriteInput!) {
              deleteReportFavorite(input: $input) {
                reportFavorite {
                  id
                }
              }
            }
          `,
          // Parameters
          variables: {
            input: payload,
          },
        })
        .then(() => {
          // refresh plants
          this.$apollo.queries.reportFavorites.refresh();
        })
        .catch((error) => {
          this.error = error.graphQLErrors[0].message;
        })
        .finally(() => (this.isLoadingSearch = false));
    },

    updateUnderMaintenance(isUnderMaintenance) {
      this.isLoadingUnderMaintenance = true;

      // prepare api call payload
      var payload = {
        id: "VW5kZXJNYWludGVuYW5jZU5vZGU6MQ==",
        isUnderMaintenance: isUnderMaintenance,
      };

      this.$apollo
        .mutate({
          // Query
          mutation: gql`
            mutation updateUnderMaintenance(
              $input: UpdateUnderMaintenanceInput!
            ) {
              updateUnderMaintenance(input: $input) {
                underMaintenance {
                  id
                  isUnderMaintenance
                }
              }
            }
          `,
          // Parameters
          variables: {
            input: payload,
          },
        })
        .then(() => {
          // refresh plants
          this.$apollo.queries.underMaintenance.refresh();
        })
        .catch((error) => {
          this.error = error.graphQLErrors[0].message;
        })
        .finally(() => (this.isLoadingUnderMaintenance = false));
    },
  },
};
</script>
