<template>
	<v-row justify="center">
		<!-- Edit profile -->
		<v-dialog v-model="dialog" persistent max-width="600px">
			<v-card>
				<v-alert v-if="error" tile type="error">{{ error }}</v-alert>

				<v-card-title class="primary white--text">
					{{ formTitle }}
				</v-card-title>

				<v-card-text class="pt-5">
					<v-form ref="form">
						<v-row>
							<!-- Tenant -->
							<v-col cols="8">
								<v-autocomplete
									v-model="editedItem.tenantNodeId"
									:items="tenants.edges"
									item-text="node.tenantName"
									item-value="node.id"
									:loading="$apollo.queries.tenants.loading"
									:rules="[rules.required]"
									v-bind:label="
										$t('reportEditDialog.tenantLabel')
									"
									class="subtitle-2"
									outlined
									clearable
								>
								</v-autocomplete>
							</v-col>
							<v-col
								cols="4"
								v-if="editedItem.reportType === 'POWERBIREPORT'"
							>
								<v-switch
									v-model="editedItem.isEditable"
									v-bind:label="
										$t('reportEditDialog.isEditableLabel')
									"
									class="mt-3 my-0 py-0"
									hide-details
								></v-switch>
							</v-col>
							<!-- report group -->
							<v-col cols="12">
								<v-autocomplete
									v-model="editedItem.reportGroupNodeId"
									:items="reportGroups.edges"
									item-text="node.reportGroupName"
									item-value="node.id"
									:loading="
										$apollo.queries.reportGroups.loading
									"
									:rules="[]"
									v-bind:label="
										$t('reportEditDialog.reportGroupLabel')
									"
									class="subtitle-2"
									outlined
									clearable
								>
								</v-autocomplete>
							</v-col>
							<v-col cols="12">
								<v-text-field
									v-model="editedItem.reportName"
									:counter="100"
									:rules="[
										rules.required,
										rules.maxLength(100),
									]"
									v-bind:label="
										$t('reportEditDialog.reportNameLabel')
									"
									outlined
									class="subtitle-2"
								></v-text-field>
							</v-col>
							<v-col cols="12">
								<v-text-field
									v-model="editedItem.groupId"
									:counter="36"
									:rules="[
										rules.required,
										rules.minLength(36),
										rules.maxLength(36),
									]"
									v-bind:label="
										$t('reportEditDialog.groupIdLabel')
									"
									outlined
									class="subtitle-2"
								></v-text-field>
							</v-col>
							<v-col cols="12">
								<v-text-field
									v-model="editedItem.reportId"
									:counter="36"
									:rules="[
										rules.required,
										rules.minLength(36),
										rules.maxLength(36),
									]"
									v-bind:label="
										$t('reportEditDialog.reportIdLabel')
									"
									outlined
									class="subtitle-2"
								></v-text-field>
							</v-col>
							<v-col
								cols="12"
								v-if="editedItem.reportType === 'POWERBIREPORT'"
							>
								<v-text-field
									v-model="editedItem.datasetId"
									:counter="36"
									:rules="[
										rules.required,
										rules.minLength(36),
										rules.maxLength(36),
									]"
									v-bind:label="
										$t('reportEditDialog.datasetIdLabel')
									"
									outlined
									class="subtitle-2"
								></v-text-field>
							</v-col>
							<v-col
								cols="8"
								v-if="editedItem.reportType === 'POWERBIREPORT'"
							>
								<v-text-field
									v-model="editedItem.bindedGroupId"
									:counter="36"
									:rules="[rules.maxLength(36)]"
									v-bind:label="
										$t(
											'reportEditDialog.bindedGroupIdLabel'
										)
									"
									outlined
									class="subtitle-2"
								></v-text-field>
							</v-col>
							<v-col
								cols="4"
								v-if="editedItem.reportType === 'POWERBIREPORT'"
							>
								<v-switch
									v-model="editedItem.isBinded"
									v-bind:label="
										$t('reportEditDialog.isBindedLabel')
									"
									class="mt-3 my-0 py-0"
									hide-details
								></v-switch>
							</v-col>
							<v-col
								cols="8"
								v-if="editedItem.reportType === 'POWERBIREPORT'"
							>
								<v-text-field
									v-model="editedItem.bindedDatasetId"
									:counter="36"
									:rules="[rules.maxLength(36)]"
									v-bind:label="
										$t(
											'reportEditDialog.bindedDatasetIdLabel'
										)
									"
									outlined
									class="subtitle-2"
								></v-text-field>
							</v-col>
							<v-col cols="4">
								<v-switch
									v-model="editedItem.isDirectQuery"
									label="Direct Query"
									class="mt-3 my-0 py-0"
									hide-details
								></v-switch>
							</v-col>
							<v-col
								cols="6"
								v-if="editedItem.reportType === 'POWERBIREPORT'"
							>
								<v-text-field
									v-model="editedItem.pbiDateTable"
									:counter="30"
									:rules="[rules.maxLength(30)]"
									v-bind:label="
										$t('reportEditDialog.pbiDateTableLabel')
									"
									outlined
									class="subtitle-2"
								></v-text-field>
							</v-col>
							<v-col
								cols="6"
								v-if="editedItem.reportType === 'POWERBIREPORT'"
							>
								<v-text-field
									v-model="editedItem.pbiDateColumn"
									:counter="30"
									:rules="[rules.maxLength(30)]"
									v-bind:label="
										$t(
											'reportEditDialog.pbiDateColumnLabel'
										)
									"
									outlined
									class="subtitle-2"
								></v-text-field>
							</v-col>
							<v-col cols="6">
								<v-autocomplete
									v-model="editedItem.reportType"
									:items="reportTypeChoices"
									item-text="text"
									item-value="value"
									:rules="[]"
									label="Report Type"
									class="subtitle-2"
									outlined
									clearable
								>
								</v-autocomplete>
							</v-col>
						</v-row>
					</v-form>
				</v-card-text>

				<v-card-actions class="pb-5 pr-5">
					<v-spacer></v-spacer>
					<v-btn
						:disabled="isSaving"
						text
						rounded
						@click="closeReportDialog"
						>{{ $t("button.cancelBtn") }}</v-btn
					>
					<v-btn
						outlined
						rounded
						:loading="isSaving"
						color="primary"
						@click="saveReport"
						>{{ $t("button.saveBtn") }}</v-btn
					>
				</v-card-actions>
			</v-card>
		</v-dialog>
	</v-row>
</template>

<script>
import gql from "graphql-tag";
import helper from "@/utils/helper.js";

export default {
	name: "report-edit-dialog",
	props: ["dialog", "object"],

	apollo: {
		tenants: {
			query: gql`
				query tenants {
					tenants {
						edges {
							node {
								id
								tenantName
							}
						}
					}
				}
			`,
			variables() {},
			// Additional options here
			fetchPolicy: "cache-and-network",
			update: (data) => data.tenants,
			skip: true,
			pollInterval: 60000, // ms
		},

		reportGroups: {
			query: gql`
				query reportGroups {
					reportGroups {
						edges {
							node {
								id
								reportGroupName
							}
						}
					}
				}
			`,
			variables() {},
			// Additional options here
			fetchPolicy: "cache-and-network",
			update: (data) => data.reportGroups,
			skip: true,
			pollInterval: 60000, // ms
		},
	},

	data() {
		return {
			error: null,
			isSaving: false,

			tenants: {},
			reportGroups: {},

			editedItem: {
				id: null,
				reportName: null,
				groupId: null,
				reportId: null,
				datasetId: null,
				isBinded: false,
				bindedGroupId: null,
				bindedDatasetId: null,
				pbiDateTable: null,
				pbiDateColumn: null,
				isEditable: false,
				isDirectQuery: false,
				reportGroupNodeId: null,
				tenantNodeId: null,
				reportType: null,
			},
			defaultItem: {
				id: null,
				reportName: null,
				groupId: null,
				reportId: null,
				datasetId: null,
				isBinded: false,
				bindedGroupId: null,
				bindedDatasetId: null,
				pbiDateTable: null,
				pbiDateColumn: null,
				isEditable: false,
				isDirectQuery: false,
				reportGroupNodeId: null,
				tenantNodeId: null,
				reportType: null,
			},
			rules: {
				required: (v) => !helper.isEmpty(v) || "This field is required",
				minLength: (len) => (v) =>
					(v || "").length >= len ||
					`Invalid character length, required ${len}`,
				maxLength: (len) => (v) =>
					(v || "").length <= len ||
					"Invalid character length, too long",
			},

			reportTypeChoices: [
				{ value: "POWERBIREPORT", text: "Power BI Report" },
				{ value: "PAGINATEDREPORT", text: "Paginated Report" },
			],
		};
	},
	computed: {
		formTitle() {
			return this.object
				? this.$t("reportEditDialog.title")
				: this.$t("reportEditDialog.title");
		},
	},
	watch: {
		// reset form when dialog open or close
		dialog(val) {
			// if open dialog, initialize selections
			if (val) {
				this.$apollo.queries.tenants.skip = false;
			}
			if (val) {
				this.$apollo.queries.reportGroups.skip = false;
			}

			this.resetForm();
		},
	},
	created() {},
	methods: {
		resetForm() {
			// reset form state
			this.error = null;
			this.isSaving = false;
			this.panel = 0;
			if (this.$refs.form) {
				this.$refs.form.resetValidation();
			}

			// when editing, set form with object
			if (this.dialog && this.object) {
				this.editedItem.id = this.object.id;
				this.editedItem.reportName = this.object.reportName;
				this.editedItem.groupId = this.object.groupId;
				this.editedItem.reportId = this.object.reportId;
				this.editedItem.datasetId = this.object.datasetId;
				this.editedItem.isBinded = this.object.isBinded;
				this.editedItem.bindedGroupId = this.object.bindedGroupId;
				this.editedItem.bindedDatasetId = this.object.bindedDatasetId;
				this.editedItem.pbiDateTable = this.object.pbiDateTable;
				this.editedItem.pbiDateColumn = this.object.pbiDateColumn;
				this.editedItem.isEditable = this.object.isEditable;
				this.editedItem.isDirectQuery = this.object.isDirectQuery;
				this.editedItem.reportGroupNodeId =
					this.object.reportGroup === null
						? null
						: this.object.reportGroup.id;
				this.editedItem.tenantNodeId = this.object.tenant.id;
				this.editedItem.reportType = this.object.reportType;
			}
			// when new or closing, set form with default
			else {
				this.editedItem = JSON.parse(JSON.stringify(this.defaultItem));
			}
		},

		closeReportDialog() {
			this.$emit("update:dialog", false);
			this.resetForm();
		},

		saveReport() {
			if (!this.$refs.form.validate()) {
				return;
			}

			// set form state
			this.error = null;
			this.isSaving = true;

			// prepare api call payload
			var payload = {
				reportName: this.editedItem.reportName,
				groupId: this.editedItem.groupId,
				reportId: this.editedItem.reportId,
				datasetId: this.editedItem.datasetId,
				isBinded: this.editedItem.isBinded,
				bindedGroupId: this.editedItem.bindedGroupId,
				bindedDatasetId: this.editedItem.bindedDatasetId,
				pbiDateTable: this.editedItem.pbiDateTable,
				pbiDateColumn: this.editedItem.pbiDateColumn,
				isEditable: this.editedItem.isEditable,
				isDirectQuery: this.editedItem.isDirectQuery,
				reportGroupNodeId: this.editedItem.reportGroupNodeId,
				tenantNodeId: this.editedItem.tenantNodeId,
				reportType: this.editedItem.reportType,
			};

			// if editing
			if (this.object) {
				// prepare graphql mutation payload
				payload.id = this.editedItem.id;

				// update
				this.$apollo
					.mutate({
						// Query
						mutation: gql`
							mutation updateReport($input: UpdateReportInput!) {
								updateReport(input: $input) {
									report {
										id
										reportName
										groupId
										reportId
										datasetId
										pbiDateTable
										pbiDateColumn
										isEditable
										isDirectQuery
										isActive
										reportGroup {
											id
											reportGroupName
										}
										tenant {
											id
											tenantName
										}
									}
								}
							}
						`,
						// Parameters
						variables: {
							input: payload,
						},
					})
					.then((response) => {
						// update parent object
						this.$emit("changed", response);
						this.closeReportDialog();

						// show snackbar
						const payload = {
							color: "success",
							message: `Report successfully edited`,
						};
						this.$store.dispatch("snackbar/showMessage", payload);
					})
					.catch((error) => {
						this.error = error.graphQLErrors[0].message;
						this.isSaving = false;

						// show snackbar
						const payload = {
							color: "error",
							message: this.error,
						};
						this.$store.dispatch("snackbar/showMessage", payload);
					})
					.finally(() => {});
			}
		},
	},
};
</script>
