const state = {
	appBarTitle: null,
	disableAppBar: false,
};

const mutations = {
  SET_APP_BAR_TITLE(state, payload) {
    state.appBarTitle = payload;
  },

  SET_DISABLE_APP_BAR(state, payload) {
    state.disableAppBar = payload;
  },
};

const actions = {
  setAppBarTitle(context, payload) {
    context.commit("SET_APP_BAR_TITLE", payload);
  },

  setDisableAppBar(context, payload) {
    context.commit("SET_DISABLE_APP_BAR", payload);
  },
};

const getters = {
  getAppBarTitle(state) {
    return state.appBarTitle;
  },

  getDisableAppBar(state) {
    return state.disableAppBar;
  },
};

export default {
	namespaced: true,
	state,
	getters,
	mutations,
	actions,
};
