<template>
  <v-row class="mt-2">
    <v-col class="py-0" cols="12">
      <v-alert tile type="error" v-if="error">{{ error }}</v-alert>
    </v-col>
    <v-col cols="12" md="2" sm="6">
      <v-text-field
        @click:append-outer="refresh()"
        @click:clear="clearSearch()"
        @keydown.enter="refresh()"
        clearable
        dense
        hide-details
        outlined
        required
        :label="$t('datatable.searchLabel')"
        v-model="search"
      ></v-text-field>
    </v-col>

    <v-col cols="12" md="2" sm="6" v-if="me.isSuperuser">
      <v-autocomplete
        :items="tenants.edges"
        :label="$t('reportsSettings.usermappings.datatableHeaders.userTenant')"
        :loading="$apollo.queries.tenants.loading"
        @change="refresh()"
        clearable
        dense
        hide-details
        item-text="node.tenantName"
        item-value="node.id"
        outlined
        v-model="selectedUserTenant"
      >
      </v-autocomplete>
    </v-col>
    
    <v-col cols="12" md="2" sm="6" v-if="me.isSuperuser">
      <v-autocomplete
        :items="tenants.edges"
        :label="$t('reportsSettings.usermappings.datatableHeaders.reportTenant')"
        :loading="$apollo.queries.tenants.loading"
        @change="refresh()"
        clearable
        dense
        hide-details
        item-text="node.tenantName"
        item-value="node.id"
        outlined
        v-model="selectedReportTenant"
      >
      </v-autocomplete>
    </v-col>

    <v-spacer />
    <v-col align="right" cols="12" md="2" sm="6">
      <v-btn @click="resetFilters()" color="primary" dark rounded small text>
        {{ $t("button.clearFiltersBtn") }}
      </v-btn>
    </v-col>

    <v-col cols="12" md="12">
      <v-data-table
        :headers="me.isSuperuser ? headersSuperuser : headers"
        :loading="$apollo.queries.usermappings.loading"
        :items="usermappings.edges"
        :options.sync="dataTableOptions"
        :server-items-length="usermappings.totalCount"
        :footer-props="{
          'disable-pagination': $apollo.queries.usermappings.loading,
          'items-per-page-options': [10, 25, 50, 75, 100],
        }"
        :show-select="
          hasPermission('dashboards.delete_usermapping')
            ? 'show-select'
            : undefined
        "
        item-key="node.id"
        v-model="selectedItems"
      >
        <template v-slot:[`item.action`]="{ item }">
          <v-btn
            @click="edit(item.node)"
            color="primary"
            icon
            v-if="hasPermission('dashboards.change_usermapping')"
          >
            <v-icon>edit</v-icon>
          </v-btn>
        </template>
      </v-data-table>
    </v-col>

    <DeleteDialog
      :dialog.sync="deleteDialog"
      v-on:confirmed="onDeleteConfirmed"
    />

    <UsermappingAddDialog :dialog.sync="addDialog" v-on:changed="onChanged" />

    <UsermappingEditDialog
      :dialog.sync="editDialog"
      :object.sync="editedItem"
      v-on:changed="onChanged"
    />
  </v-row>
</template>

<script>
import DeleteDialog from "@/components/base/DeleteDialog.vue";
import gql from "graphql-tag";
import helper from "@/utils/helper.js";
import UsermappingAddDialog from "@/components/reports/UsermappingAddDialog.vue";
import UsermappingEditDialog from "@/components/reports/UsermappingEditDialog.vue";

export default {
  name: "usermappings-list",
  components: {
    DeleteDialog,
    UsermappingAddDialog,
    UsermappingEditDialog,
  },

  apollo: {
    tenants: {
      query: gql`
        query tenants {
          tenants(orderBy: ["tenantName"]) {
            edges {
              node {
                id
                tenantName
              }
            }
          }
        }
      `,
      variables() {},
      fetchPolicy: "cache-and-network",
      update: (data) => data.tenants,
      skip: true,
    },

    usermappings: {
      query: gql`
        query usermappings(
          $first: Int
          $last: Int
          $before: String
          $after: String
          $search: String
          $orderBy: [String]
          $report_Tenant: ID
          $user_Tenant: ID
        ) {
          usermappings(
            first: $first
            last: $last
            before: $before
            after: $after
            search: $search
            orderBy: $orderBy
            report_Tenant: $report_Tenant
            user_Tenant: $user_Tenant
          ) {
            edgeCount
            totalCount
            pageInfo {
              startCursor
              endCursor
              hasPreviousPage
              hasNextPage
            }
            edges {
              node {
                id
                user {
                  id
                  email
                  firstName
                  lastName
                  tenant {
                    id
                    tenantName
                  }
                }
                report {
                  id
                  reportName
                  tenant {
                    id
                    tenantName
                  }
                }
                rlsRole
              }
            }
          }
        }
      `,
      variables() {},
      fetchPolicy: "cache-and-network",
      update: (data) => data.usermappings,
      skip: true,
    },
  },

  data: function () {
    return {
      addDialog: false,
      deleteDialog: false,
      editDialog: false,
      editedItem: {},
      error: null,
      selectedItems: [],
      tenants: {},
      usermappings: {},

      headers: [
        {
          text: this.$t("reportsSettings.usermappings.datatableHeaders.email"),
          value: "node.user.email",
          sortable: true,
        },
        {
          text: this.$t(
            "reportsSettings.usermappings.datatableHeaders.reportName"
          ),
          value: "node.report.reportName",
          sortable: true,
        },
        {
          text: this.$t(
            "reportsSettings.usermappings.datatableHeaders.rlsRole"
          ),
          value: "node.rlsRole",
          sortable: true,
        },
        {
          text: this.$t("datatable.actionHeader"),
          align: "center",
          value: "action",
          sortable: false,
        },
      ],

      headersSuperuser: [
        {
          text: this.$t(
            "reportsSettings.usermappings.datatableHeaders.userTenant"
          ),
          value: "node.user.tenant.tenantName",
          sortable: true,
        },
        {
          text: this.$t("reportsSettings.usermappings.datatableHeaders.email"),
          value: "node.user.email",
          sortable: true,
        },
        {
          text: this.$t(
            "reportsSettings.usermappings.datatableHeaders.reportTenant"
          ),
          value: "node.report.tenant.tenantName",
          sortable: true,
        },
        {
          text: this.$t(
            "reportsSettings.usermappings.datatableHeaders.reportName"
          ),
          value: "node.report.reportName",
          sortable: true,
        },
        {
          text: this.$t(
            "reportsSettings.usermappings.datatableHeaders.rlsRole"
          ),
          value: "node.rlsRole",
          sortable: true,
        },
        {
          text: this.$t("datatable.actionHeader"),
          align: "center",
          value: "action",
          sortable: false,
        },
      ],
    };
  },

  computed: {
    me() {
      return this.$store.state.user.me;
    },

    dataTableOptions: {
      get() {
        return this.$store.getters["usermappingsList/getDataTableOptions"];
      },
      set(value) {
        this.$store.dispatch("usermappingsList/setDataTableOptions", value);
      },
    },

    search: {
      get() {
        return this.$store.getters["usermappingsList/getSearch"];
      },
      set(value) {
        this.$store.dispatch("usermappingsList/setSearch", value);
      },
    },

    selectedReportTenant: {
      get() {
        return this.$store.getters["usermappingsList/getSelectedReportTenant"];
      },
      set(value) {
        this.$store.dispatch("usermappingsList/setSelectedReportTenant", value);
      },
    },
    
    selectedUserTenant: {
      get() {
        return this.$store.getters["usermappingsList/getSelectedUserTenant"];
      },
      set(value) {
        this.$store.dispatch("usermappingsList/setSelectedUserTenant", value);
      },
    },
  },

  watch: {
    dataTableOptions() {
      this.refresh();
    },

    selectedItems() {
      this.$emit("updateNrOfSelectedItems", this.selectedItems.length);
    },
  },

  created() {
    this.hasPermission = helper.hasPermission;
    this.orderByList = helper.orderByList;

    if (this.me.isSuperuser) {
      this.$apollo.queries.tenants.skip = false;
    }
  },

  mounted() {},

  methods: {
    add() {
      this.addDialog = true;
    },

    clearSearch() {
      this.search = null;
      this.refresh();
    },

    deleteSelected() {
      this.deleteDialog = true;
    },

    edit(item) {
      this.editedItem = item;
      this.editDialog = true;
    },

    onChanged() {
      this.$apollo.queries.usermappings.refresh();
    },

    onDeleteConfirmed() {
      var ids = [];
      this.selectedItems.forEach(function (item, index) {
        ids.push(item.node.id);
      });

      const payload = {
        ids: ids,
      };

      this.$apollo
        .mutate({
          mutation: gql`
            mutation deleteUsermappings($input: DeleteUsermappingsInput!) {
              deleteUsermappings(input: $input) {
                usermappings {
                  id
                }
              }
            }
          `,
          variables: {
            input: payload,
          },
        })
        .then(() => {
          this.$apollo.queries.usermappings.refresh();

          this.deleteDialog = false;
          this.selectedItems = [];

          const payload = {
            color: "success",
            message: `Usermappings successfully deleted`,
          };
          this.$store.dispatch("snackbar/showMessage", payload);
        })
        .catch((error) => {
          this.error = error;
        });
    },

    refresh() {
      const { sortBy, sortDesc, page, itemsPerPage } = this.dataTableOptions;

      var _first = itemsPerPage;
      var _last = null;
      var _before = null;
      var _after = null;

      if (page < this.page) {
        _first = null;
        _last = itemsPerPage;
        _before = this.usermappings.pageInfo.startCursor;
        _after = null;
      }

      if (page > this.page) {
        _first = itemsPerPage;
        _last = null;
        _before = null;
        _after = this.usermappings.pageInfo.endCursor;
      }

      var orderByList = this.orderByList(sortBy, sortDesc);

      this.$apollo.queries.usermappings.setVariables({
        first: _first,
        last: _last,
        before: _before,
        after: _after,
        orderBy: orderByList,
        search: this.search,
        report_Tenant: this.selectedReportTenant,
        user_Tenant: this.selectedUserTenant,
      });

      this.$apollo.queries.usermappings.skip = false;
      this.$apollo.queries.usermappings.refresh();

      this.page = page;
    },

    resetFilters() {
      this.selectedReportTenant = null;
      this.selectedUserTenant = null;
      this.clearSearch();
    },
  },
};
</script>
