import { ACCESS_TOKEN_KEY } from "@/vue-apollo";
import store from "../store";
import Vue from "vue";
import VueRouter from "vue-router";

import Announcements from "@/views/Announcements.vue";
import GroupDetail from "@/views/GroupDetail.vue";
import Home from "@/views/Home.vue";
import KnowledgeBase from "@/views/KnowledgeBase.vue";
import KnowledgeBaseDetail from "@/views/KnowledgeBaseDetail.vue";
import KnowledgeBaseSettings from "@/views/KnowledgeBaseSettings.vue";
import Login from "@/views/Login.vue";
import PaginatedReportDetail from "@/views/PaginatedReportDetail.vue";
import ReportDetail from "@/views/ReportDetail.vue";
import Reports from "@/views/Reports.vue";
import ResetPassword from "@/views/ResetPassword.vue";
import Settings from "@/views/Settings.vue";
import Status from "@/views/Status.vue";
import StatusSettings from "@/views/StatusSettings.vue";
import Users from "@/views/Users.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/login",
    name: "login",
    component: Login,
  },
  {
    path: "/resetpassword/:uidb64/:token",
    name: "resetpassword",
    component: ResetPassword,
  },
  {
    path: "/",
    name: "home",
    component: Home,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/announcements",
    name: "announcements",
    component: Announcements,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/users",
    name: "users",
    component: Users,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/group/:groupNodeId",
    name: "group-detail",
    component: GroupDetail,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/reports",
    name: "reports",
    component: Reports,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/report/:reportNodeId",
    name: "report-detail",
    component: ReportDetail,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/paginated-report/:reportNodeId",
    name: "paginated-report-detail",
    component: PaginatedReportDetail,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/status",
    name: "status",
    component: Status,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/status/settings",
    name: "status-settings",
    component: StatusSettings,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/settings",
    name: "settings",
    component: Settings,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/knowledge-base",
    name: "knowledge-base",
    component: KnowledgeBase,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/knowledge-base/settings",
    name: "knowledge-base-settings",
    component: KnowledgeBaseSettings,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/knowledge-base/:id",
    name: "knowledge-base-detail",
    component: KnowledgeBaseDetail,
    meta: {
      requiresAuth: true,
    },
  },
];

const router = new VueRouter({
  routes,
});

router.beforeEach((to, from, next) => {
  store.dispatch("app/setAppBarTitle", null);

  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (localStorage.getItem(ACCESS_TOKEN_KEY)) {
      next();
    } else {
      next({
        name: "login",
        query: { redirect: to.fullPath },
      });
    }
  } else {
    next();
  }

  if (to.meta.title) {
    var title = to.meta.title;
  } else {
    var title = "BI Portal";
  }

  document.title = `${title} | Insurance Data`;
});

export default router;
