<template>
  <v-row class="mt-2">
    <v-col class="py-0" cols="12">
      <v-alert tile type="error" v-if="error">{{ error }}</v-alert>
    </v-col>
    <v-col cols="12" md="2" sm="6">
      <v-text-field
        @click:append-outer="refresh()"
        @click:clear="clearSearch()"
        @keydown.enter="refresh()"
        clearable
        dense
        hide-details
        outlined
        required
        :label="$t('datatable.searchLabel')"
        v-model="search"
      ></v-text-field>
    </v-col>
    <v-col cols="12" md="2" sm="6">
      <v-autocomplete
        :items="reportGroups.edges"
        :label="$t('reports.datatableHeaders.reportGroup')"
        :loading="$apollo.queries.reportGroups.loading"
        @change="refresh()"
        clearable
        dense
        hide-details
        item-text="node.reportGroupName"
        item-value="node.id"
        outlined
        v-model="selectedReportGroup"
      >
      </v-autocomplete>
    </v-col>
    <v-col cols="12" md="2" sm="6" v-if="me.isSuperuser">
      <v-autocomplete
        :items="tenants.edges"
        :label="$t('reports.datatableHeaders.tenant')"
        :loading="$apollo.queries.tenants.loading"
        @change="refresh()"
        clearable
        dense
        hide-details
        item-text="node.tenantName"
        item-value="node.id"
        outlined
        v-model="selectedTenant"
      >
      </v-autocomplete>
    </v-col>

    <v-spacer />
    <v-col align="right" cols="12" md="2" sm="6">
      <v-btn @click="resetFilters()" color="primary" dark rounded small text>
        {{ $t("button.clearFiltersBtn") }}
      </v-btn>
    </v-col>

    <v-col cols="12" md="12">
      <v-data-table
        :headers="me.isSuperuser ? headersSuperuser : headers"
        :loading="$apollo.queries.reports.loading"
        :items="reports.edges"
        :options.sync="dataTableOptions"
        :server-items-length="reports.totalCount"
        :footer-props="{
          'disable-pagination': $apollo.queries.reports.loading,
          'items-per-page-options': [10, 25, 50, 75, 100],
        }"
        :show-select="me.isSuperuser ? 'show-select' : undefined"
        @click:row="
          (item) => goToReportDetail(item.node.id, item.node.reportType)
        "
        class="row-pointer"
        item-key="node.id"
        v-model="selectedItems"
      >
        <template v-slot:[`item.node.reportType`]="{ item }">
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-icon
                v-on="on"
                :color="iconConfig[item.node.reportType]?.color || 'primary'"
              >
                {{ iconConfig[item.node.reportType]?.icon }}
              </v-icon>
            </template>
            <span>{{ iconConfig[item.node.reportType]?.tooltip }}</span>
          </v-tooltip>
        </template>

        <template v-slot:[`item.node.lastRefreshStatus`]="{ item }">
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-icon
                v-on="on"
                :color="iconConfig[item.node.lastRefreshStatus]?.color"
              >
                {{ iconConfig[item.node.lastRefreshStatus]?.icon }}
              </v-icon>
            </template>
            <span>
              {{
                item.node.lastRefreshStatus === "Unknown"
                  ? "Refreshing"
                  : item.node.lastRefreshStatus
              }}
            </span>
          </v-tooltip>
        </template>

        <template v-slot:[`item.node.lastRefreshEndTime`]="{ item }">
          <span v-if="item.node.lastRefreshEndTime">{{
            item.node.lastRefreshEndTime | moment("YYYY-MM-DD HH:mm")
          }}</span>
          <span v-else> </span>
        </template>

        <template v-slot:[`item.node.refreshScheduleIsEnabled`]="{ item }">
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-icon
                v-on="on"
                :color="
                  iconConfig[
                    item.node.isDirectQuery
                      ? 'DirectQuery'
                      : item.node.refreshScheduleIsEnabled
                      ? 'Enabled'
                      : 'Disabled'
                  ].color
                "
              >
                {{
                  iconConfig[
                    item.node.isDirectQuery
                      ? "DirectQuery"
                      : item.node.refreshScheduleIsEnabled
                      ? "Enabled"
                      : "Disabled"
                  ].icon
                }}
              </v-icon>
            </template>
            <span>
              {{
                iconConfig[
                  item.node.isDirectQuery
                    ? "DirectQuery"
                    : item.node.refreshScheduleIsEnabled
                    ? "Enabled"
                    : "Disabled"
                ].tooltip
              }}
            </span>
          </v-tooltip>
        </template>

        <template v-slot:[`item.action`]="{ item }">
          <template v-if="item.node.favoriteSet.edges.length > 0">
            <template v-for="favorite in item.node.favoriteSet.edges">
              <v-btn
                :key="favorite.node.id"
                :loading="$apollo.queries.reports.loading"
                @click.stop="deleteFavorite(favorite.node.id)"
                color="primary"
                icon
                v-if="favorite.node.isFavorite"
              >
                <v-icon>star</v-icon>
              </v-btn>
            </template>
          </template>
          <template v-else>
            <v-btn
              :loading="$apollo.queries.reports.loading"
              @click.stop="createFavorite(item.node.id)"
              color="primary"
              icon
            >
              <v-icon>star_border</v-icon>
            </v-btn>
          </template>
          <v-btn
            @click.stop="edit(item.node)"
            color="primary"
            icon
            v-if="me.isSuperuser"
          >
            <v-icon>edit</v-icon>
          </v-btn>
        </template>
      </v-data-table>
    </v-col>

    <DeleteDialog
      :dialog.sync="deleteDialog"
      v-on:confirmed="onDeleteConfirmed"
    />

    <ReportAddDialog :dialog.sync="addDialog" v-on:changed="onChanged" />

    <ReportEditDialog
      :dialog.sync="editDialog"
      :object.sync="editedItem"
      v-on:changed="onChanged"
    />
  </v-row>
</template>

<script>
import DeleteDialog from "@/components/base/DeleteDialog.vue";
import gql from "graphql-tag";
import helper from "@/utils/helper.js";
import ReportAddDialog from "@/components/reports/ReportAddDialog.vue";
import ReportEditDialog from "@/components/reports/ReportEditDialog.vue";

export default {
  name: "reports-list",
  components: {
    DeleteDialog,
    ReportAddDialog,
    ReportEditDialog,
  },

  apollo: {
    reports: {
      query: gql`
        query reports(
          $first: Int
          $last: Int
          $before: String
          $after: String
          $search: String
          $orderBy: [String]
          $allReports: Boolean!
          $reportGroup: ID
          $tenant: ID
          $usermappingSet_User_Id_Iexact: ID
        ) {
          reports(
            first: $first
            last: $last
            before: $before
            after: $after
            search: $search
            orderBy: $orderBy
            allReports: $allReports
            reportGroup: $reportGroup
            tenant: $tenant
            usermappingSet_User_Id_Iexact: $usermappingSet_User_Id_Iexact
          ) {
            edgeCount
            totalCount
            pageInfo {
              startCursor
              endCursor
              hasPreviousPage
              hasNextPage
            }
            edges {
              node {
                id
                reportName
                groupId
                reportId
                datasetId
                isBinded
                bindedGroupId
                bindedDatasetId
                pbiDateTable
                pbiDateColumn
                isEditable
                lastRefreshType
                lastRefreshStartTime
                lastRefreshEndTime
                lastRefreshStatus
                refreshScheduleTimes
                refreshScheduleIsEnabled
                isActive
                isDirectQuery
                reportType
                reportGroup {
                  id
                  reportGroupName
                }
                tenant {
                  id
                  tenantName
                }
                favoriteSet {
                  edges {
                    node {
                      id
                      isFavorite
                    }
                  }
                }
              }
            }
          }
        }
      `,
      variables() {},
      fetchPolicy: "cache-and-network",
      update: (data) => data.reports,
      skip: true,
      pollInterval: 60000, // ms
    },

    reportGroups: {
      query: gql`
        query reportGroups {
          reportGroups(orderBy: ["reportGroupName"]) {
            edges {
              node {
                id
                reportGroupName
              }
            }
          }
        }
      `,
      variables() {},
      fetchPolicy: "cache-and-network",
      update: (data) => {
        return data.reportGroups;
      },
    },

    tenants: {
      query: gql`
        query tenants {
          tenants(orderBy: ["tenantName"]) {
            edges {
              node {
                id
                tenantName
              }
            }
          }
        }
      `,
      variables() {},
      fetchPolicy: "cache-and-network",
      update: (data) => data.tenants,
      skip: true,
    },
  },

  data: function () {
    return {
      addDialog: false,
      deleteDialog: false,
      editDialog: false,
      editedItem: {},
      error: null,
      iconConfig: {
        Completed: { icon: "check_circle", color: "success" },
        DirectQuery: {
          icon: "swap_horizontal_circle",
          color: "info",
          tooltip: "Direct Query",
        },
        Disabled: { icon: "cancel", color: "error" },
        Disabled: { icon: "cancel", color: "error", tooltip: "Disabled" },
        Enabled: { icon: "check_circle", color: "success", tooltip: "Enabled" },
        Failed: { icon: "cancel", color: "error" },
        PAGINATEDREPORT: {
          icon: "layers",
          color: "primary",
          tooltip: "Paginated Report",
        },
        POWERBIREPORT: {
          icon: "dashboard",
          color: "primary",
          tooltip: "Dashboard",
        },
        Unknown: { icon: "error", color: "warning" },
      },
      reportGroups: {},
      reports: {},
      selectedItems: [],
      tenants: {},

      headers: [
        {
          text: "Report Type",
          value: "node.reportType",
          sortable: false,
        },
        {
          text: this.$t("reports.datatableHeaders.tenant"),
          value: "node.tenant.tenantName",
          sortable: true,
        },
        {
          text: this.$t("reports.datatableHeaders.report"),
          value: "node.reportName",
          sortable: true,
        },
        {
          text: this.$t("reports.datatableHeaders.reportGroup"),
          value: "node.reportGroup.reportGroupName",
          sortable: true,
        },
        {
          text: this.$t("reports.datatableHeaders.lastRefreshStatus"),
          value: "node.lastRefreshStatus",
          sortable: true,
        },
        {
          text: this.$t("reports.datatableHeaders.lastRefresh"),
          value: "node.lastRefreshEndTime",
          sortable: true,
        },
        {
          text: this.$t("reports.datatableHeaders.refreshScheduleStatus"),
          value: "node.refreshScheduleIsEnabled",
          sortable: true,
        },
        {
          text: this.$t("reports.datatableHeaders.refreshSchedule"),
          value: "node.refreshScheduleTimes",
          sortable: true,
        },
        {
          text: this.$t("datatable.actionHeader"),
          align: "center",
          value: "action",
          sortable: false,
        },
      ],

      headersSuperuser: [
        {
          text: "Report Type",
          value: "node.reportType",
          sortable: false,
        },
        {
          text: this.$t("reports.datatableHeaders.tenant"),
          value: "node.tenant.tenantName",
          sortable: true,
        },
        {
          text: this.$t("reports.datatableHeaders.report"),
          value: "node.reportName",
          sortable: true,
        },
        {
          text: this.$t("reports.datatableHeaders.reportGroup"),
          value: "node.reportGroup.reportGroupName",
          sortable: true,
        },
        {
          text: this.$t("reports.datatableHeaders.lastRefreshStatus"),
          value: "node.lastRefreshStatus",
          sortable: false,
        },
        {
          text: this.$t("reports.datatableHeaders.lastRefresh"),
          value: "node.lastRefreshEndTime",
          sortable: true,
        },
        {
          text: this.$t("reports.datatableHeaders.refreshScheduleStatus"),
          value: "node.refreshScheduleIsEnabled",
          sortable: true,
        },
        {
          text: this.$t("reports.datatableHeaders.refreshSchedule"),
          value: "node.refreshScheduleTimes",
          sortable: true,
        },
        {
          text: this.$t("datatable.actionHeader"),
          align: "center",
          value: "action",
          sortable: false,
        },
      ],
    };
  },

  computed: {
    me() {
      return this.$store.state.user.me;
    },

    dataTableOptions: {
      get() {
        return this.$store.getters["reportsList/getDataTableOptions"];
      },
      set(value) {
        this.$store.dispatch("reportsList/setDataTableOptions", value);
      },
    },

    search: {
      get() {
        return this.$store.getters["reportsList/getSearch"];
      },
      set(value) {
        this.$store.dispatch("reportsList/setSearch", value);
      },
    },

    selectedReportGroup: {
      get() {
        return this.$store.getters["reportsList/getSelectedReportGroup"];
      },
      set(value) {
        this.$store.dispatch("reportsList/setSelectedReportGroup", value);
      },
    },

    selectedTenant: {
      get() {
        return this.$store.getters["reportsList/getSelectedTenant"];
      },
      set(value) {
        this.$store.dispatch("reportsList/setSelectedTenant", value);
      },
    },
  },

  watch: {
    dataTableOptions() {
      this.refresh();
    },

    selectedItems() {
      this.$emit("updateNrOfSelectedItems", this.selectedItems.length);
    },
  },

  created() {
    this.hasPermission = helper.hasPermission;
    this.orderByList = helper.orderByList;

    if (this.me.isSuperuser) {
      this.$apollo.queries.tenants.skip = false;
    }
  },

  mounted() {},

  methods: {
    add() {
      this.addDialog = true;
    },

    clearSearch() {
      this.search = null;
      this.refresh();
    },

    createFavorite(reportNodeId) {
      var payload = {
        reportNodeId: reportNodeId,
        isFavorite: true,
      };

      this.$apollo
        .mutate({
          mutation: gql`
            mutation createReportFavorite($input: CreateReportFavoriteInput!) {
              createReportFavorite(input: $input) {
                reportFavorite {
                  id
                }
              }
            }
          `,
          variables: {
            input: payload,
          },
        })
        .then(() => {
          this.$apollo.queries.reports.refresh();
        })
        .catch((error) => {
          this.error = error;
        });
    },

    deleteFavorite(reportFavoriteId) {
      var payload = {
        id: reportFavoriteId,
      };

      this.$apollo
        .mutate({
          mutation: gql`
            mutation deleteReportFavorite($input: DeleteReportFavoriteInput!) {
              deleteReportFavorite(input: $input) {
                reportFavorite {
                  id
                }
              }
            }
          `,
          variables: {
            input: payload,
          },
        })
        .then(() => {
          this.$apollo.queries.reports.refresh();
        })
        .catch((error) => {
          this.error = error;
        });
    },

    deleteSelected() {
      this.deleteDialog = true;
    },

    edit(item) {
      this.editedItem = item;
      this.editDialog = true;
    },

    goToReportDetail(reportNodeId, reportType) {
      if (reportType === "POWERBIREPORT") {
        this.$router.push({
          name: "report-detail",
          params: {
            reportNodeId: reportNodeId,
          },
        });
      } else if (reportType === "PAGINATEDREPORT") {
        this.$router.push({
          name: "paginated-report-detail",
          params: {
            reportNodeId: reportNodeId,
          },
        });
      }
    },

    onChanged() {
      this.$apollo.queries.reports.refresh();
    },

    onDeleteConfirmed() {
      var ids = [];
      this.selectedItems.forEach(function (item, index) {
        ids.push(item.node.id);
      });

      const payload = {
        ids: ids,
      };

      this.$apollo
        .mutate({
          mutation: gql`
            mutation deleteReports($input: DeleteReportsInput!) {
              deleteReports(input: $input) {
                reports {
                  id
                }
              }
            }
          `,
          variables: {
            input: payload,
          },
        })
        .then(() => {
          this.$apollo.queries.reports.refresh();

          this.deleteDialog = false;
          this.selectedItems = [];

          const payload = {
            color: "success",
            message: `Reports successfully deleted`,
          };
          this.$store.dispatch("snackbar/showMessage", payload);
        })
        .catch((error) => {
          this.error = error;
        });
    },

    refresh() {
      const { sortBy, sortDesc, page, itemsPerPage } = this.dataTableOptions;

      var _first = itemsPerPage;
      var _last = null;
      var _before = null;
      var _after = null;

      if (page < this.page) {
        _first = null;
        _last = itemsPerPage;
        _before = this.reports.pageInfo.startCursor;
        _after = null;
      }

      if (page > this.page) {
        _first = itemsPerPage;
        _last = null;
        _before = null;
        _after = this.reports.pageInfo.endCursor;
      }

      var orderByList = this.orderByList(sortBy, sortDesc);

      var userId = "";
      if (this.me.isSuperuser) {
        userId = "";
      } else {
        userId = this.me.id;
      }

      this.$apollo.queries.reports.setVariables({
        first: _first,
        last: _last,
        before: _before,
        after: _after,
        orderBy: orderByList,
        search: this.search,
        allReports: false,
        reportGroup: this.selectedReportGroup,
        tenant: this.selectedTenant,
        usermappingSet_User_Id_Iexact: userId,
      });

      this.$apollo.queries.reports.skip = false;
      this.$apollo.queries.reports.refresh();

      this.page = page;
    },

    resetFilters() {
      this.selectedReportGroup = null;
      this.selectedTenant = null;
      this.clearSearch();
    },
  },
};
</script>

<style>
.row-pointer > .v-data-table__wrapper > table > tbody > tr:hover {
  cursor: pointer;
}
</style>
