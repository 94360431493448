<template>
	<v-row justify="center">
		<!-- Edit profile -->
		<v-dialog v-model="dialog" persistent max-width="500px">
			<v-card>
				<v-alert v-if="error" tile type="error">{{ error }}</v-alert>

				<v-card-title class="primary white--text">
					{{ formTitle }}
				</v-card-title>

				<v-card-text class="pt-5">
					<v-form ref="form">
						<v-row>
							<!-- Theme Name -->
							<v-col cols="12">
								<v-text-field
									v-model="editedItem.themeName"
									:counter="200"
									:rules="[
										rules.required,
										rules.maxLength(200),
									]"
									v-bind:label="
										$t('themeEditDialog.themeNameLabel')
									"
									outlined
									class="subtitle-2"
								></v-text-field>
							</v-col>

							<v-col cols="12">
								<v-text-field
									v-model="editedItem.primaryColor"
									hide-details
									outlined
									class="subtitle-2"
									hint="Default: #4c49f6"
									persistent-hint
								>
									<template v-slot:append>
										<v-menu
											v-model="menu"
											top
											nudge-bottom="105"
											nudge-left="16"
											:close-on-content-click="false"
										>
											<template v-slot:activator="{ on }">
												<div
													:style="swatchStyle"
													v-on="on"
												/>
											</template>
											<v-card>
												<v-card-text class="pa-0">
													<v-color-picker
														v-model="
															editedItem.primaryColor
														"
														flat
														mode="hexa"
													/>
												</v-card-text>
											</v-card>
										</v-menu>
									</template>
								</v-text-field>
							</v-col>

							<v-col cols="12">
								<v-file-input
									v-model="selectedImage"
									outlined
									chips
									v-bind:label="
										$t('themeEditDialog.themeLogoLabel')
									"
									prepend-icon=""
									accept="image/*"
									@change="onFileChange"
								></v-file-input>
							</v-col>
							<v-col cols="12">
								<v-card>
									<v-card-text>
										{{
											$t(
												"themeEditDialog.themeLogoPreviewLabel"
											)
										}}
									</v-card-text>
									<v-img
										label="Theme logo preview"
										:src="imageUrl"
										style="border: 1px dashed #ccc; max-height: 300px"
										contain
										class="grey"
									>
									</v-img>
								</v-card>
							</v-col>
						</v-row>
					</v-form>
				</v-card-text>

				<v-card-actions class="pb-5 pr-5">
					<v-spacer></v-spacer>
					<v-btn
						:disabled="isSaving"
						text
						rounded
						@click="closeThemeDialog"
						>{{ $t("button.cancelBtn") }}</v-btn
					>
					<v-btn
						outlined
						rounded
						:loading="isSaving"
						color="primary"
						@click="saveTheme"
						>{{ $t("button.saveBtn") }}</v-btn
					>
				</v-card-actions>
			</v-card>
		</v-dialog>
	</v-row>
</template>

<script>
import gql from "graphql-tag";
import helper from "@/utils/helper.js";

export default {
	name: "theme-edit-dialog",
	props: ["dialog", "object"],
	data() {
		return {
			error: null,
			isSaving: false,

			menu: false,

			selectedImage: null,
			imageUrl: null,

			editedItem: {
				id: null,
				themeName: null,
				primaryColor: null,
			},
			defaultItem: {
				id: null,
				themeName: null,
				primaryColor: "#4c49f6",
			},
			rules: {
				required: (v) => !helper.isEmpty(v) || "This field is required",
				minLength: (len) => (v) =>
					(v || "").length >= len ||
					`Invalid character length, required ${len}`,
				maxLength: (len) => (v) =>
					(v || "").length <= len ||
					"Invalid character length, too long",
			},
		};
	},
	computed: {
		formTitle() {
			return this.object
				? this.$t("themeEditDialog.editTitle")
				: this.$t("themeEditDialog.newTitle");
		},

		swatchStyle() {
			const { primaryColor, menu } = this;
			return {
				backgroundColor: this.editedItem.primaryColor,
				cursor: "pointer",
				height: "30px",
				width: "30px",
				borderRadius: menu ? "50%" : "4px",
				transition: "border-radius 200ms ease-in-out",
			};
		},
	},
	watch: {
		// reset form when dialog open or close
		dialog(val) {
			this.resetForm();
		},
	},
	created() {},
	methods: {
		resetForm() {
			// reset form state
			this.error = null;
			this.isSaving = false;
			this.panel = 0;
			if (this.$refs.form) {
				this.$refs.form.resetValidation();
			}

			// when editing, set form with object
			if (this.dialog && this.object) {
				this.editedItem.id = this.object.id;
				this.editedItem.themeName = this.object.themeName;
				this.editedItem.primaryColor = this.object.primaryColor;
				this.selectedImage = new File(
					["foo"],
					this.object.themeLogoPath,
					{
						type: "text/plain",
					}
				);
				this.imageUrl = this.object.themeLogoPath;
			}
			// when new or closing, set form with default
			else {
				this.editedItem = JSON.parse(JSON.stringify(this.defaultItem));
				this.selectedImage = null;
				this.imageUrl = null;
			}
		},

		closeThemeDialog() {
			this.$emit("update:dialog", false);
			this.resetForm();
		},

		createImage(file) {
			const reader = new FileReader();

			reader.onload = (e) => {
				this.imageUrl = e.target.result;
			};
			reader.readAsDataURL(file);
		},

		onFileChange(file) {
			if (!file) {
				this.imageUrl = null;
			}
			this.createImage(file);
		},

		saveTheme() {
			if (!this.$refs.form.validate()) {
				return;
			}

			// set form state
			this.error = null;
			this.isSaving = true;

			// prepare api call payload
			var payload = {
				themeName: this.editedItem.themeName,
				primaryColor: this.editedItem.primaryColor,
				themeLogo: this.imageUrl,
			};

			// if editing
			if (this.object) {
				// prepare graphql mutation payload
				payload.id = this.editedItem.id;

				// update
				this.$apollo
					.mutate({
						// Query
						mutation: gql`
							mutation updateTheme($input: UpdateThemeInput!) {
								updateTheme(input: $input) {
									theme {
										id
										themeName
										primaryColor
										themeLogoPath
									}
								}
							}
						`,
						// Parameters
						variables: {
							input: payload,
						},
					})
					.then((response) => {
						// update parent object
						this.$emit("changed", response);
						this.closeThemeDialog();

						// show snackbar
						const payload = {
							color: "success",
							message: `Theme successfully edited`,
						};
						this.$store.dispatch("snackbar/showMessage", payload);
					})
					.catch((error) => {
						this.error = error.graphQLErrors[0].message;
						this.isSaving = false;

						// show snackbar
						const payload = {
							color: "error",
							message: this.error,
						};
						this.$store.dispatch("snackbar/showMessage", payload);
					})
					.finally(() => {});
			}
			// new
			else {
				this.$apollo
					.mutate({
						// Query
						mutation: gql`
							mutation createTheme($input: CreateThemeInput!) {
								createTheme(input: $input) {
									theme {
										id
									}
								}
							}
						`,
						// Parameters
						variables: {
							input: payload,
						},
					})
					.then((response) => {
						this.$emit(
							"changed",
							response.data.createTheme.theme
						);
						this.closeThemeDialog();

						// show snackbar
						const payload = {
							color: "success",
							message: `Theme successfully added`,
						};
						this.$store.dispatch("snackbar/showMessage", payload);
					})
					.catch((error) => {
						console.log(error);

						this.error = error.graphQLErrors
							.map((error) => error.message)
							.join(", ");
						this.isSaving = false;

						// show snackbar
						const payload = {
							color: "error",
							message: this.error,
						};
						this.$store.dispatch("snackbar/showMessage", payload);
					})
					.finally(() => {});
			}
		},
	},
};
</script>
