import store from "../store/index.js";

export default {
  typeOf(obj) {
    return {}.toString.call(obj).split(" ")[1].slice(0, -1).toLowerCase();
  },

  isEmpty(input) {
    // array
    if (this.typeOf(input) === "array") {
      return input.length === 0;
    }

    // int
    if (this.typeOf(input) === "number") {
      if (input === 0) {
        return false;
      }

      return !input;
    }

    // undefined, null, string, object
    return !input || Object.keys(input).length === 0;
  },

  hasPermission(codename) {
    if (!store.state.user.me) {
      return false;
    }

    if (store.state.user.me.require2fa && !store.state.user.me.enabled2fa) {
      return false;
    }

    const _permissions = store.state.user.me.allPermissions;

    let _check_permission = _permissions.includes(codename);

    return _check_permission ? true : false;
    // return codename;
  },

  orderByList(sortBy, sortDesc) {
    var orderByList = [];
    sortBy.forEach(function (item, index) {
      let field = item.replace("node.", "").replace(/\./g, "__");
      let direction = sortDesc[index];
      if (direction === true) {
        field = "-" + field;
      }
      orderByList.push(field);
    });

    return orderByList;
  },
};
