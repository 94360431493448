<template>
  <v-row justify="center">
    <!-- Edit profile -->
    <v-dialog v-model="dialog" persistent max-width="600px">
      <v-card>
        <v-alert v-if="error" tile type="error">{{ error }}</v-alert>

        <v-card-title class="primary white--text">
          {{ formTitle }}
        </v-card-title>

        <v-card-text class="pt-5">
          <v-form ref="form">
            <v-row>
              <!-- User -->
              <v-col cols="12">
                <v-autocomplete
                  v-model="selectedUsers"
                  :items="users.edges"
                  item-text="node.email"
                  item-value="node.id"
                  :search-input.sync="searchUsers"
                  cache-items
                  :loading="$apollo.queries.users.loading"
                  :rules="[rules.required]"
                  v-bind:label="$t('usermappingAddDialog.usersLabel')"
                  class="subtitle-2"
                  outlined
                  clearable
                  chips
                  small-chips
                  multiple
                >
                </v-autocomplete>
                <v-autocomplete
                  v-model="selectedReports"
                  :items="reports.edges"
                  :filter="filterUsermappingReports"
                  item-text="node.reportName"
                  item-value="node.id"
                  :search-input.sync="searchReports"
                  cache-items
                  :loading="$apollo.queries.reports.loading"
                  :rules="[rules.required]"
                  v-bind:label="$t('usermappingAddDialog.reportsLabel')"
                  class="subtitle-2"
                  outlined
                  clearable
                  chips
                  small-chips
                  multiple
                >
                  <template slot="item" slot-scope="{ item }"
                    >{{ item.node.tenant.tenantName }} -
                    {{ item.node.reportName }}</template
                  >
                </v-autocomplete>
                <v-text-field
                  v-model="rlsRole"
                  :counter="600"
                  :rules="[rules.maxLength(600)]"
                  v-bind:label="$t('usermappingAddDialog.rlsRoleLabel')"
                  persistent-hint
                  v-bind:hint="$t('usermappingAddDialog.rlsRoleHint')"
                  outlined
                  class="subtitle-2"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>

        <v-card-actions class="pb-5 pr-5">
          <v-spacer></v-spacer>
          <v-btn
            :disabled="isSaving"
            text
            rounded
            @click="closeUsermappingDialog"
            >{{ $t("button.cancelBtn") }}</v-btn
          >
          <v-btn
            outlined
            rounded
            :loading="isSaving"
            color="primary"
            @click="saveUsermapping"
            >{{ $t("button.saveBtn") }}</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import gql from "graphql-tag";
import helper from "@/utils/helper.js";

export default {
  name: "usermapping-edit-dialog",
  props: ["dialog", "object"],

  apollo: {
    users: {
      query: gql`
        query users($search: String, $orderBy: [String]) {
          users(search: $search, orderBy: $orderBy) {
            edges {
              node {
                id
                email
              }
            }
          }
        }
      `,
      variables() {},
      fetchPolicy: "cache-and-network",
      update: (data) => data.users,
      skip: true,
    },

    reports: {
      query: gql`
        query reports(
          $search: String
          $orderBy: [String]
          $allReports: Boolean!
        ) {
          reports(search: $search, orderBy: $orderBy, allReports: $allReports) {
            edges {
              node {
                id
                reportName
                tenant {
                  id
                  tenantName
                }
              }
            }
          }
        }
      `,
      variables() {},
      fetchPolicy: "cache-and-network",
      update: (data) => data.reports,
      skip: true,
    },
  },

  data() {
    return {
      error: null,
      isSaving: false,

      searchUsers: null,
      searchReports: null,

      editedItem: {},
      defaultItem: {},

      users: {},
      searchUser: null,
      selectedUsers: [],

      reports: {},
      searchReport: null,
      selectedReports: [],

      rlsRole: null,

      rules: {
        required: (v) => !helper.isEmpty(v) || "This field is required",
        minLength: (len) => (v) =>
          (v || "").length >= len ||
          `Invalid character length, required ${len}`,
        maxLength: (len) => (v) =>
          (v || "").length <= len || "Invalid character length, too long",
      },
    };
  },
  computed: {
    formTitle() {
      return this.object
        ? this.$t("usermappingAddDialog.title")
        : this.$t("usermappingAddDialog.title");
    },
    me() {
      return this.$store.state.user.me;
    },
  },
  watch: {
    // reset form when dialog open or close
    dialog(val) {
      // if open dialog, initialize selections
      if (val) {
				this.refreshReports()
				this.refreshUsers()
      }

      this.resetForm();
    },

    searchUsers(val) {
      val && val !== this.select && this.refreshUsers(val);
    },

    searchReports(val) {
      val && val !== this.select && this.refreshReports(val);
    },
  },
  created() {},
  methods: {
    refreshUsers(searchVal) {
      this.$apollo.queries.users.setVariables({
        search: searchVal,
        orderBy: ["email"],
      });

      // set skip false to execute the query
      this.$apollo.queries.users.skip = false;
      this.$apollo.queries.users.refresh();
    },

    refreshReports(searchVal) {
      this.$apollo.queries.reports.setVariables({
        search: searchVal,
        orderBy: ["reportName"],
        allReports: true,
      });

      // set skip false to execute the query
      this.$apollo.queries.reports.skip = false;
      this.$apollo.queries.reports.refresh();
    },

    resetForm() {
      // reset form state
      this.error = null;
      this.isSaving = false;
      this.searchUser = null;
      this.selectedUsers = [];
      this.searchReport = null;
      this.selectedReports = [];
      this.rlsRole = null;

      if (this.$refs.form) {
        this.$refs.form.resetValidation();
      }
    },

    closeUsermappingDialog() {
      this.$emit("update:dialog", false);
      this.resetForm();
    },

    saveUsermapping() {
      this.isSaving = true;

      // prepare api call payload
      var payload = {
        users: this.selectedUsers,
        reports: this.selectedReports,
        rlsRole: this.rlsRole,
      };

      this.$apollo
        .mutate({
          // Query
          mutation: gql`
            mutation createUsermappings($input: CreateUsermappingsInput!) {
              createUsermappings(input: $input) {
                usermapping {
                  id
                }
              }
            }
          `,
          // Parameters
          variables: {
            input: payload,
          },
        })
        .then((response) => {
          this.$emit("changed", response);
          this.closeUsermappingDialog();

          // show snackbar
          const payload = {
            color: "success",
            message: `Usermapping successfully added`,
          };
          this.$store.dispatch("snackbar/showMessage", payload);
        })
        .catch((error) => {
          console.log(error);

          this.error = error.graphQLErrors
            .map((error) => error.message)
            .join(", ");
          this.isSaving = false;

          // show snackbar
          const payload = {
            color: "error",
            message: this.error,
          };
          this.$store.dispatch("snackbar/showMessage", payload);
        })
        .finally(() => {});
    },

    filterUsermappingReports(item, queryText, itemText) {
      const textOne = item.node.tenant.tenantName.toLowerCase();
      const textTwo = item.node.reportName.toLowerCase();
      const searchText = queryText.toLowerCase();

      return (
        textOne.indexOf(searchText) > -1 || textTwo.indexOf(searchText) > -1
      );
    },
  },
};
</script>
