import { apolloClient } from "@/vue-apollo";
import { TASK_RESULT_EXTENSION_FILE_STATUS_QUERY } from "@/graphql/tasks.js";

const state = {
  taskResultExtensionFileStatus: null,
};

const mutations = {
  SET_TASK_RESULT_EXTENSION_FILE_STATUS(state, payload) {
    state.taskResultExtensionFileStatus =
      payload.taskResultExtensionFileStatus.status;
  },
};

const actions = {
  async fetchTaskResultExtensionFileStatus(context) {
    return new Promise((resolve, reject) => {
      apolloClient
        .query({
          query: TASK_RESULT_EXTENSION_FILE_STATUS_QUERY,
          fetchPolicy: "network-only",
        })
        .then((response) => {
          context.commit("SET_TASK_RESULT_EXTENSION_FILE_STATUS", {
            ...response.data,
          });

          resolve();
        })
        .catch((error) => {
          console.log(error);
          reject(error);
        });
    });
  },
};

const getters = {
  getTaskResultExtensionFileStatus(state) {
    return state.taskResultExtensionFileStatus;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
