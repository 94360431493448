import gql from "graphql-tag";

//////////////////////////////////////////////////
// Task result extension file status
/////////////////////////////////////////////////

export const TASK_RESULT_EXTENSION_FILE_STATUS_QUERY = gql`
  query {
    taskResultExtensionFileStatus {
      status
    }
  }
`;
