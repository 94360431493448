<template>
  <v-row justify="center">
    <!-- add empty row to fit to page on mobile -->
    <v-row class="ma-0 pa-0"></v-row>

    <!-- Edit customer -->
    <v-dialog v-model="dialog" persistent max-width="600px">
      <v-card>
        <v-alert v-if="error" tile type="error">{{ error }}</v-alert>

        <v-card-title class="primary white--text">
          {{ formTitle }}
          <v-spacer></v-spacer>
        </v-card-title>

        <v-card-text class="pt-5">
          <v-form ref="form">
            <v-row>
              <v-col cols="12">
                <v-text-field
                  v-model="editedItem.reportGroupName"
                  :counter="100"
                  :rules="[rules.required, rules.maxLength(100)]"
                  v-bind:label="
                    $t('reportGroupEditDialog.reportGroupNameLabel')
                  "
                  outlined
                  class="subtitle-2"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>

        <v-card-actions class="pb-5 pr-5">
          <v-spacer></v-spacer>
          <v-btn
            :disabled="isSaving"
            text
            rounded
            @click="closeReportGroupDialog"
            >{{ $t("button.cancelBtn") }}</v-btn
          >
          <v-btn
            outlined
            rounded
            :loading="isSaving"
            color="primary"
            @click="saveReportGroup"
            >{{ $t("button.saveBtn") }}</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import gql from "graphql-tag";
import helper from "@/utils/helper.js";

export default {
  name: "report-group-edit-dialog",
  props: ["dialog", "object"],

  apollo: {},

  data() {
    return {
      error: null,
      isSaving: false,

      defaultItem: {
        id: null,
        reportGroupName: null,
      },
      editedItem: {
        id: null,
        reportGroupName: null,
      },
      rules: {
        required: (v) => !helper.isEmpty(v) || "This field is required",
        minLength: (len) => (v) =>
          (v || "").length >= len ||
          `Invalid character length, required ${len}`,
        maxLength: (len) => (v) => (v || "").length <= len || "Too long",
      },
    };
  },
  computed: {
    formTitle() {
      return this.object
        ? this.$t("reportGroupEditDialog.editTitle")
        : this.$t("reportGroupEditDialog.newTitle");
    },
    me() {
      return this.$store.state.user.me;
    },
  },
  watch: {
    // reset form when dialog open or close
    dialog(val) {
      this.resetForm();
    },
  },
  created() {},
  methods: {
    resetForm() {
      // reset form state
      this.error = null;
      this.isSaving = false;
      this.panel = 0;
      if (this.$refs.form) {
        this.$refs.form.resetValidation();
      }

      // when editing, set form with object
      if (this.dialog && this.object) {
        this.editedItem.id = this.object.id;
        this.editedItem.reportGroupName = this.object.reportGroupName;
      }
      // when new or closing, set form with default
      else {
        this.editedItem = JSON.parse(JSON.stringify(this.defaultItem));
      }
    },

    closeReportGroupDialog() {
      this.$emit("update:dialog", false);
      this.resetForm();
    },

    saveReportGroup() {
      if (!this.$refs.form.validate()) {
        return;
      }

      // set form state
      this.error = null;
      this.isSaving = true;

      // prepare api call payload
      var payload = {
        reportGroupName: this.editedItem.reportGroupName,
      };

      // if editing
      if (this.object) {
        // prepare graphql mutation payload
        payload.id = this.editedItem.id;

        // update
        this.$apollo
          .mutate({
            // Query
            mutation: gql`
              mutation updateReportGroup($input: UpdateReportGroupInput!) {
                updateReportGroup(input: $input) {
                  reportGroup {
                    id
                    reportGroupName
                  }
                }
              }
            `,
            // Parameters
            variables: {
              input: payload,
            },
          })
          .then((response) => {
            // update parent object
            this.$emit("changed", response);
            this.closeReportGroupDialog();

            // show snackbar
            const payload = {
              color: "success",
              message: `Report Group successfully edited`,
            };
            this.$store.dispatch("snackbar/showMessage", payload);
          })
          .catch((error) => {
            this.error = error.graphQLErrors[0].message;
            this.isSaving = false;
          })
          .finally(() => {});
      }
      // new
      else {
        this.$apollo
          .mutate({
            // Query
            mutation: gql`
              mutation createReportGroup($input: CreateReportGroupInput!) {
                createReportGroup(input: $input) {
                  reportGroup {
                    id
                    reportGroupName
                  }
                }
              }
            `,
            // Parameters
            variables: {
              input: payload,
            },
          })
          .then((response) => {
            this.$emit("changed", response);
            this.closeReportGroupDialog();

            // show snackbar
            const payload = {
              color: "success",
              message: `Report Group successfully added`,
            };
            this.$store.dispatch("snackbar/showMessage", payload);
          })
          .catch((error) => {
            console.log(error);

            this.error = error.graphQLErrors
              .map((error) => error.message)
              .join(", ");
            this.isSaving = false;
          })
          .finally(() => {});
      }
    },
  },
};
</script>
