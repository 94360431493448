<template>
	<v-row justify="center">
		<!-- Theme Dialog -->
		<ThemeEditDialog
			:dialog.sync="themeDialog"
			v-on:item-created="themeAdded"
		></ThemeEditDialog>

		<!-- Edit profile -->
		<v-dialog v-model="dialog" persistent max-width="500px">
			<v-card>
				<v-alert v-if="error" tile type="error">{{ error }}</v-alert>

				<v-card-title class="primary white--text">
					{{ formTitle }}
				</v-card-title>

				<v-card-text class="pt-5">
					<v-form ref="form">
						<v-row>
							<!-- Tenant Name -->
							<v-col cols="12">
								<v-text-field
									v-model="editedItem.tenantName"
									:counter="200"
									:rules="[
										rules.required,
										rules.maxLength(200),
									]"
									v-bind:label="
										$t('tenantEditDialog.tenantNameLabel')
									"
									outlined
									class="subtitle-2"
								></v-text-field>
							</v-col>
							<!-- External Id -->
							<v-col cols="8">
								<v-text-field
									v-model="editedItem.externalId"
									:counter="50"
									:rules="[rules.maxLength(50)]"
									v-bind:label="
										$t('tenantEditDialog.externalIdLabel')
									"
									outlined
									class="subtitle-2"
								></v-text-field>
							</v-col>
							<!-- Max Users -->
							<v-col cols="4">
								<v-text-field
									v-model="editedItem.maxUsers"
									v-bind:label="
										$t('tenantEditDialog.maxUsersLabel')
									"
									outlined
									class="subtitle-2"
									type="number"
								></v-text-field>
							</v-col>
							<!-- Allowed Hosts -->
							<v-col cols="12" md="12">
								<v-textarea
									v-model="editedItem.allowedHosts"
									:counter="8000"
									:rules="[
										rules.required,
										rules.maxLength(8000),
									]"
									v-bind:label="
										$t('tenantEditDialog.allowedHostsLabel')
									"
									outlined
									class="subtitle-2"
									hint='["127.0.0.1", "192.168.2.3"]'
									persistent-hint
								></v-textarea>
							</v-col>
							<!-- groups -->
							<v-col cols="12">
								<v-autocomplete
									v-model="editedItem.selectedGroups"
									:items="groups.edges"
									item-text="node.name"
									item-value="node.id"
									:loading="$apollo.queries.groups.loading"
									:rules="[rules.required]"
									v-bind:label="
										$t('tenantEditDialog.groupsLabel')
									"
									class="subtitle-2"
									outlined
									clearable
									multiple
								>
								</v-autocomplete>
							</v-col>
							<!-- Theme -->
							<v-col cols="12">
								<v-autocomplete
									v-model="editedItem.themeNodeId"
									:items="themes.edges"
									item-text="node.themeName"
									item-value="node.id"
									:loading="$apollo.queries.themes.loading"
									:rules="[rules.required]"
									v-bind:label="
										$t('tenantEditDialog.themeLabel')
									"
									class="subtitle-2"
									outlined
									clearable
								>
									<template v-slot:append-outer>
										<v-btn text small tabindex="-1">
											<v-icon
												color="grey"
												@click="addTheme"
												tabindex="-1"
												>add</v-icon
											>
										</v-btn>
									</template>
								</v-autocomplete>
							</v-col>
						</v-row>
					</v-form>
				</v-card-text>

				<v-card-actions class="pb-5 pr-5">
					<v-spacer></v-spacer>
					<v-btn
						:disabled="isSaving"
						text
						rounded
						@click="closeTenantDialog"
						>{{ $t("button.cancelBtn") }}</v-btn
					>
					<v-btn
						outlined
						rounded
						:loading="isSaving"
						color="primary"
						@click="saveTenant"
						>{{ $t("button.saveBtn") }}</v-btn
					>
				</v-card-actions>
			</v-card>
		</v-dialog>
	</v-row>
</template>

<script>
import gql from "graphql-tag";
import helper from "@/utils/helper.js";
import ThemeEditDialog from "@/components/users/ThemeEditDialog.vue";

export default {
	name: "tenant-edit-dialog",
	props: ["dialog", "object"],
	components: {
		ThemeEditDialog,
	},

	apollo: {
		groups: {
			query: gql`
				query groups {
					groups {
						edges {
							node {
								id
								name
							}
						}
					}
				}
			`,
			variables() {},
			// Additional options here
			fetchPolicy: "cache-and-network",
			update: (data) => data.groups,
			skip: true,
			pollInterval: 60000, // ms
		},

		themes: {
			query: gql`
				query themes {
					themes {
						edges {
							node {
								id
								themeName
							}
						}
					}
				}
			`,
			variables() {},
			// Additional options here
			fetchPolicy: "cache-and-network",
			update: (data) => data.themes,
			skip: true,
			pollInterval: 60000, // ms
		},
	},

	data() {
		return {
			error: null,
			isSaving: false,
			themeDialog: false,

			groups: {},
			themes: {},

			editedItem: {
				id: null,
				tenantName: null,
				externalId: null,
				maxUsers: null,
				allowedHosts: null,
				themeNodeId: "",
				selectedGroups: [],
			},
			defaultItem: {
				id: null,
				tenantName: null,
				externalId: null,
				maxUsers: null,
				allowedHosts: '["*"]',
				themeNodeId: "VGhlbWVOb2RlOjE=",
				selectedGroups: [],
			},
			rules: {
				required: (v) => !helper.isEmpty(v) || "This field is required",
				minLength: (len) => (v) =>
					(v || "").length >= len ||
					`Invalid character length, required ${len}`,
				maxLength: (len) => (v) =>
					(v || "").length <= len ||
					"Invalid character length, too long",
			},
		};
	},
	computed: {
		formTitle() {
			return this.object
				? this.$t("tenantEditDialog.editTitle")
				: this.$t("tenantEditDialog.newTitle");
		},
	},
	watch: {
		// reset form when dialog open or close
		dialog(val) {
			// if open dialog, initialize selections
			if (val) {
				this.$apollo.queries.groups.skip = false;
				this.$apollo.queries.themes.skip = false;
			}

			this.resetForm();
		},
	},
	created() {},
	methods: {
		resetForm() {
			// reset form state
			this.error = null;
			this.isSaving = false;
			this.panel = 0;
			if (this.$refs.form) {
				this.$refs.form.resetValidation();
			}

			// when editing, set form with object
			if (this.dialog && this.object) {
				this.editedItem.id = this.object.id;
				this.editedItem.tenantName = this.object.tenantName;
				this.editedItem.externalId = this.object.externalId;
				this.editedItem.maxUsers = this.object.maxUsers;
				this.editedItem.allowedHosts = this.object.allowedHosts;
				this.editedItem.themeNodeId = this.object.theme.id;
				this.editedItem.selectedGroups = this.object.tenantGroupSet
					? this.object.tenantGroupSet.edges.map((item) => {
							return item.node.group.id;
					  })
					: [];
			}
			// when new or closing, set form with default
			else {
				this.editedItem = JSON.parse(JSON.stringify(this.defaultItem));
			}
		},

		closeTenantDialog() {
			this.$emit("update:dialog", false);
			this.resetForm();
		},

		saveTenant() {
			if (!this.$refs.form.validate()) {
				return;
			}

			// set form state
			this.error = null;
			this.isSaving = true;

			// prepare api call payload
			var payload = {
				tenantName: this.editedItem.tenantName,
				externalId: this.editedItem.externalId,
				maxUsers: this.editedItem.maxUsers,
				allowedHosts: this.editedItem.allowedHosts,
				themeNodeId: this.editedItem.themeNodeId,
				groups: this.editedItem.selectedGroups,
			};

			// if editing
			if (this.object) {
				// prepare graphql mutation payload
				payload.id = this.editedItem.id;

				// update
				this.$apollo
					.mutate({
						// Query
						mutation: gql`
							mutation updateTenant($input: UpdateTenantInput!) {
								updateTenant(input: $input) {
									tenant {
										id
										tenantName
										externalId
										maxUsers
									}
								}
							}
						`,
						// Parameters
						variables: {
							input: payload,
						},
					})
					.then((response) => {
						// update parent object
						this.$emit("changed", response);
						this.closeTenantDialog();

						// show snackbar
						const payload = {
							color: "success",
							message: `Tenant successfully edited`,
						};
						this.$store.dispatch("snackbar/showMessage", payload);
					})
					.catch((error) => {
						this.error = error.graphQLErrors[0].message;
						this.isSaving = false;

						// show snackbar
						const payload = {
							color: "error",
							message: this.error,
						};
						this.$store.dispatch("snackbar/showMessage", payload);
					})
					.finally(() => {});
			}
			// new
			else {
				this.$apollo
					.mutate({
						// Query
						mutation: gql`
							mutation createTenant($input: CreateTenantInput!) {
								createTenant(input: $input) {
									tenant {
										id
									}
								}
							}
						`,
						// Parameters
						variables: {
							input: payload,
						},
					})
					.then((response) => {
						this.$emit("changed", response);
						this.closeTenantDialog();

						// show snackbar
						const payload = {
							color: "success",
							message: `Tenant successfully added`,
						};
						this.$store.dispatch("snackbar/showMessage", payload);
					})
					.catch((error) => {
						console.log(error);

						this.error = error.graphQLErrors
							.map((error) => error.message)
							.join(", ");
						this.isSaving = false;

						// show snackbar
						const payload = {
							color: "error",
							message: this.error,
						};
						this.$store.dispatch("snackbar/showMessage", payload);
					})
					.finally(() => {});
			}
		},

		addTheme() {
			this.themeDialog = true;
		},
		themeAdded(item) {
			console.log(item);
			this.$apollo.queries.themes.refresh();
			this.editedItem.themeNodeId = item.id;
		},
	},
};
</script>
