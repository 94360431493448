<template>
	<v-row justify="center">
		<v-dialog v-model="dialog" persistent max-width="600px" scrollable>
			<v-card>
				<v-alert v-if="error" tile type="error" class="mb-0">{{
					error
				}}</v-alert>

				<v-card-title class="primary white--text">
					{{ formTitle }}
					<v-spacer />
				</v-card-title>

				<v-card-text class="mt-5">
					<v-form ref="form">
						<v-row>
							<v-col class="mb-0 pb-0" cols="12">
								<v-text-field
									:label="$tc('label.filter', 1)"
									class="mt-1"
									disabled
									outlined
									v-model="userReportFilter.filterName"
								></v-text-field>
							</v-col>

							<v-col cols="12" class="mt-0 pt-0 pb-5">
								<label
									><h4>
										{{
											`${$t("label.share", 1)} ${$tc(
												"label.type",
												1
											)}`
										}}
									</h4></label
								>
								<v-radio-group
									:rules="[rules.required]"
									class="mt-1"
									persistent-hint
									row
									:hint="$t('reportFiltersShareDialog.hint')"
									v-model="shareType"
								>
									<v-radio
										:label="$t('reportFiltersShareDialog.private')"
										value="PRIVATE"
									></v-radio>
									<v-radio
										:label="$t('reportFiltersShareDialog.shared')"
										value="SHARED"
									></v-radio>
									<v-radio
										:label="$t('reportFiltersShareDialog.organization')"
										value="ORGANIZATION"
									></v-radio>
								</v-radio-group>
							</v-col>

							<v-col
								cols="12"
								class="py-0"
								v-if="shareType == 'SHARED'"
							>
								<v-autocomplete
									:items="users.edges"
									:label="$tc('label.user', 2)"
									:loading="$apollo.queries.users.loading"
									:rules="[rules.required]"
									:search-input.sync="searchUsers"
									cache-items
									chips
									class="subtitle-2 mt-1"
									clearable
									item-text="node.email"
									item-value="node.id"
									multiple
									outlined
									persistent-hint
									small-chips
									v-model="selectedUsers"
									:hint="$t('reportFiltersShareDialog.userHint')"
								>
								</v-autocomplete>
							</v-col>
						</v-row>
					</v-form>
				</v-card-text>

				<v-card-actions>
					<v-spacer />
					<v-btn @click="closeDialog()" rounded text>{{
						$t("label.cancel")
					}}</v-btn>
					<v-btn
						:loading="isSaving"
						@click="updateUserReportFilter()"
						color="primary"
						outlined
						rounded
						>{{ $t("label.save") }}</v-btn
					>
				</v-card-actions>
			</v-card>
		</v-dialog>
	</v-row>
</template>

<script>
import rules from "@/utils/rules.js";
import gql from "graphql-tag";

export default {
	name: "report-filters-share-dialog",
	props: ["dialog", "userReportFilterNodeId"],
	components: {},

	apollo: {
		userReportFilter: {
			query: gql`
				query userReportFilter($userReportFilterNodeId: ID!) {
					userReportFilter(id: $userReportFilterNodeId) {
						id
						filterName
						type
						userreportfiltershareSet {
							edges {
								node {
									id
									user {
										id
										email
									}
								}
							}
						}
					}
				}
			`,
			variables() {
				return {
					userReportFilterNodeId: this.userReportFilterNodeId,
				};
			},
			fetchPolicy: "cache-and-network",
			update: (data) => data.userReportFilter,
			skip: true,
			result() {
				this.shareType = this.userReportFilter.type;

				var users = [];
				this.userReportFilter.userreportfiltershareSet.edges.forEach(
					function (item, index) {
						users.push(item.node.user.id);
					}
				);
				this.selectedUsers = users;
			},
		},

		users: {
			query: gql`
				query users($search: String) {
					users(search: $search, first: 1000, orderBy: ["email"]) {
						edges {
							node {
								id
								email
							}
						}
					}
				}
			`,
			variables() {},
			fetchPolicy: "network-only",
			update: (data) => data.users,
			skip: false,
			pollInterval: 60000,
		},
	},

	data() {
		return {
			error: null,
			isSaving: false,
			searchUsers: null,
			selectedUsers: [],
			shareType: null,
			userReportFilter: {},
			users: {},
		};
	},
	computed: {
		formTitle() {
			return this.$tc("label.report", 1) + this.$tc("label.filter", 2);
		},
	},
	watch: {
		dialog(val) {
			if (val) {
				this.$apollo.queries.userReportFilter.skip = false;
				this.$apollo.queries.userReportFilter.refresh();
				this.refreshUsers();
			}

			this.resetForm();
		},

		searchUsers(val) {
			val && val !== this.select && this.refreshUsers(val);
		},
	},
	created() {
		this.rules = rules;
	},
	methods: {
		closeDialog() {
			this.$emit("update:dialog", false);
		},

		refreshUsers(searchVal) {
			this.$apollo.queries.users.setVariables({
				search: searchVal,
			});

			this.$apollo.queries.users.skip = false;
			this.$apollo.queries.users.refresh();
		},

		resetForm() {
			this.error = null;
			this.isSaving = false;
			this.searchUsers = null;
			this.selectedUsers = [];
			this.shareType = null;
			this.userReportFilter = {};
			this.users = {};

			if (this.$refs.form) {
				this.$refs.form.resetValidation();
			}
		},

		updateUserReportFilter() {
			if (!this.$refs.form.validate()) {
				return;
			}

			this.error = null;
			this.isSaving = true;

			var payload = {
				id: this.userReportFilterNodeId,
				type: this.shareType,
				users: this.selectedUsers,
			};

			this.$apollo
				.mutate({
					mutation: gql`
						mutation updateUserReportFilter(
							$input: UpdateUserReportFilterInput!
						) {
							updateUserReportFilter(input: $input) {
								userReportFilter {
									id
								}
							}
						}
					`,
					variables: {
						input: payload,
					},
				})
				.then((response) => {
					// show snackbar
					const payload = {
						color: "success",
						message: `Filter successfully updated`,
					};
					this.$store.dispatch("snackbar/showMessage", payload);

					this.$emit("onFilterChange");
					this.closeDialog();
				})
				.catch((error) => {
					this.error = error;
				});
		},
	},
};
</script>