<template>
  <v-app id="pbiportal">
    <!-- Notification -->
    <Snackbar></Snackbar>

    <!-- Top App Bar -->
    <v-app-bar
      v-if="isLoggedIn && !disableAppBar"
      :clipped-left="$vuetify.breakpoint.lgAndUp"
      app
      color="primary"
      dark
      dense
    >
      <v-app-bar-nav-icon @click.stop="drawer = !drawer"></v-app-bar-nav-icon>
      <v-toolbar-title style="width: 300px" class="ml-0 pl-4 font-weight-bold">
        <span v-if="isLoggedIn" class="hidden-sm-and-down">
          {{ me.tenant?.theme?.themeName }}
        </span>
        <span v-else class="hidden-sm-and-down"> Insurance Data </span>
      </v-toolbar-title>

      <v-spacer></v-spacer>

      <v-toolbar-title
        v-if="appBarTitle"
        style="width: 600px"
        class="ml-0 pl-4 font-weight-bold"
      >
        <span>
          {{ appBarTitle }}
        </span>
      </v-toolbar-title>

      <v-spacer></v-spacer>

      <v-btn
        @click="goToReportsExport()"
        class="ml-5"
        color="info"
        rounded
        small
        v-if="taskResultExtensionFileStatus == 'STARTED'"
      >
        <v-progress-circular
          :size="14"
          :width="1"
          class="mr-2"
          color="white"
          indeterminate
        ></v-progress-circular>
        {{ $t("label.exporting") }}
      </v-btn>

      <v-btn
        @click="goToReportsExport()"
        class="ml-5"
        color="success"
        rounded
        small
        v-else-if="taskResultExtensionFileStatus == 'SUCCESS'"
      >
        <v-icon small left>check_circle</v-icon>
        {{ $t("label.done") }}
      </v-btn>

      <v-btn
        v-if="!me.passed2fa"
        small
        color="error"
        class="ml-5"
        href="/#/settings"
      >
        <v-icon small left>warning</v-icon>
        {{ $t("appBar.enable2faBtn") }}
      </v-btn>

      <!-- Notifications -->
      <Notifications v-if="isLoggedIn"></Notifications>

      <!-- Translations -->
      <v-menu
        offset-y
        origin="center center"
        :nudge-bottom="10"
        transition="scale-transition"
      >
        <template v-slot:activator="{ on }">
          <v-btn icon v-on="on">
            <v-icon> translate </v-icon>
          </v-btn>
        </template>
        <v-list>
          <v-list-item
            v-for="item in locales"
            :key="item.locale"
            @click="changeLocale(item.locale)"
          >
            <v-list-item-title>{{ item.localeName }}</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>

      <!-- Logo -->
      <v-btn icon large href="/#/">
        <v-avatar size="32px" item>
          <v-img v-if="isLoggedIn" :src="me.tenant?.theme?.themeLogoPath"></v-img>
          <v-img
            v-else
            src="https://idportal.blob.core.windows.net/media/theme/themelogo/Insurance%20Data/lUzE3uU38kVBDWnHMpCoM3QzK9w/Insurance_Data.png"
          ></v-img>
        </v-avatar>
      </v-btn>
    </v-app-bar>

    <!-- Navigation menu -->
    <v-navigation-drawer
      v-show="isLoggedIn"
      v-model="drawer"
      :clipped="$vuetify.breakpoint.lgAndUp"
      app
    >
      <v-list v-if="me">
        <v-list-item>
          <v-list-item-avatar color="primary">
            <v-icon dark>person</v-icon>
          </v-list-item-avatar>
          <v-list-item-content>
            <v-list-item-title
              >{{ me.firstName }} {{ me.lastName }}</v-list-item-title
            >
            <v-list-item-subtitle>{{ me.email }}</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </v-list>

      <v-divider></v-divider>

      <v-list dense>
        <!-- home -->
        <v-list-item link href="/#/">
          <v-list-item-action>
            <v-icon>home</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>{{
              $t("navigationDrawer.home")
            }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <!-- reports -->
        <v-list-item
          v-if="
            hasPermission('dashboards.view_embedded_report') && me.passed2fa
          "
          link
          href="/#/reports"
        >
          <v-list-item-action>
            <v-icon>bar_chart</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>{{
              $t("navigationDrawer.reports")
            }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-divider></v-divider>
        <!-- users -->
        <v-list-item
          v-if="hasPermission('users.view_customuser') && me.passed2fa"
          link
          href="/#/users"
        >
          <v-list-item-action>
            <v-icon>contacts</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>{{
              $t("navigationDrawer.users")
            }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <!-- settings -->
        <v-list-item link href="/#/settings">
          <v-list-item-action>
            <v-icon>settings</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>{{
              $t("navigationDrawer.settings")
            }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <!-- support -->
        <v-list-group :value="false" prepend-icon="help">
          <template v-slot:activator>
            <v-list-item-title>{{
              $t("navigationDrawer.support.title")
            }}</v-list-item-title>
          </template>

          <v-list-item link href="/#/status">
            <v-list-item-action></v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>{{
                $t("navigationDrawer.support.status")
              }}</v-list-item-title>
            </v-list-item-content>
            <v-list-item-icon>
              <v-icon>lightbulb</v-icon>
            </v-list-item-icon>
          </v-list-item>
          <v-list-item v-if="me.isSuperuser" link href="/#/knowledge-base">
            <v-list-item-action></v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>{{
                $t("navigationDrawer.support.knowledgeBase")
              }}</v-list-item-title>
            </v-list-item-content>
            <v-list-item-icon>
              <v-icon>info</v-icon>
            </v-list-item-icon>
          </v-list-item>
        </v-list-group>
      </v-list>
      <template v-slot:append>
        <div class="pa-2">
          <v-btn block text outlined rounded color="error" @click="logout">{{
            $t("button.logoutBtn")
          }}</v-btn>
        </div>
        <center class="grey--text subtitle-2 mb-2">
          v1.2.1 &copy; Insurance Data
        </center>
      </template>
    </v-navigation-drawer>

    <!-- add to load the router -->
    <v-main fill-height>
      <v-alert v-if="isLoggedInAsOtherUser" color="error lighten-2" dark>
        {{ $t("appBar.loggedInAsTitle") }} {{ me.email }}
        <v-btn dark class="ml-2" @click="logoutAsOtherUser"
          >{{ $t("button.closeBtn") }}
        </v-btn>
      </v-alert>

      <router-view
        :loggedOutAsOtherUserCounter="loggedOutAsOtherUserCounter"
      ></router-view>
    </v-main>
  </v-app>
</template>

<script>
import helper from "@/utils/helper.js";
import Snackbar from "@/components/base/Snackbar.vue";
import Notifications from "@/components/base/Notifications.vue";

export default {
  name: "App",
  components: {
    Snackbar,
    Notifications,
  },

  data: () => ({
    drawer: false,
    isLoggingOutAsOtherUser: false,
    loggedOutAsOtherUserCounter: 0,

    locales: [
      {
        locale: "en",
        localeName: "English",
      },
      {
        locale: "nl",
        localeName: "Nederlands",
      },
      {
        locale: "de",
        localeName: "Deutsch",
      },
    ],
  }),
  computed: {
    isLoggedIn() {
      return this.$store.state.user.loggedIn;
    },
    isLoggedInAsOtherUser() {
      return this.$store.state.user.loggedInAsOtherUser;
    },
    today() {
      var _today = new Date();
      _today.setHours(23, 59, 59, 59); // remove time

      return _today;
    },
    me() {
      return this.$store.state.user.me || {};
    },
    languages() {
      return Object.keys(this.$i18n.messages);
    },

    appBarTitle: {
      get() {
        return this.$store.getters["app/getAppBarTitle"];
      },
      set(value) {
        this.$store.dispatch("app/setAppBarTitle", value);
      },
    },

    disableAppBar: {
      get() {
        return this.$store.getters["app/getDisableAppBar"];
      },
      set(value) {
        this.$store.dispatch("app/setDisableAppBar", value);
      },
    },

    taskResultExtensionFileStatus() {
      return this.$store.state.tasks.taskResultExtensionFileStatus;
    },

    reportsTab: {
      get() {
        return this.$store.getters["reports/getTab"];
      },
      set(value) {
        this.$store.dispatch("reports/setTab", value);
      },
    },
  },
  watch: {
    isLoggedIn(val) {
      if (val) {
        // fetch me
        this.fetchMe();
      }
    },
    isLoggedInAsOtherUser(val) {
      if (val) {
        this.fetchMe();
      }
    },
  },
  created() {
    // copy hasPermission from helper
    this.hasPermission = helper.hasPermission;

    if (this.isLoggedIn) {
      // fetch me
      this.fetchMe();
      this.fetchTaskResultExtensionFileStatus();
      this.interval = setInterval(
        () => this.fetchTaskResultExtensionFileStatus(),
        60000
      );
    }
  },
  mounted() {},
  methods: {
    fetchMe() {
      this.$store
        .dispatch("user/fetchMe")
        .then(() => {
          try {
            this.$vuetify.theme.themes.light.primary =
              this.$store.state.user.me.tenant?.theme?.primaryColor;
            this.$gtag.set("user_properties", {
              user_email: this.$store.state.user.me.email,
              user_tenant: this.$store.state.user.me.tenant.tenantName,
            });
          } catch (err) {
            //console.log(err);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },

    fetchTaskResultExtensionFileStatus() {
      this.$store
        .dispatch("tasks/fetchTaskResultExtensionFileStatus")
        .then(() => {})
        .catch((error) => {
          console.log(error);
        });
    },

    changeLocale(locale) {
      console.log(locale);
      localStorage.setItem("locale", locale);
      this.$i18n.locale = locale;
    },

    logoutAsOtherUser() {
      this.isLoggingOutAsOtherUser = true;

      this.$store
        .dispatch("user/logoutAsOtherUser")
        .then(() => {
          if (this.$route.name != "home") {
            this.$router.push(this.$route.query.redirect || "/");
          } else {
            this.fetchMe();
          }
        })
        .catch((error) => {
          if (error.graphQLErrors) {
            for (let err of error.graphQLErrors) {
              // show snackbar
              const payload = {
                color: "error",
                message: err.message,
              };
              this.$store.dispatch("snackbar/showMessage", payload);
            }
          }
        })
        .finally(() => {
          this.isLoggingOutAsOtherUser = false;
          this.loggedOutAsOtherUserCounter += 1;
        });
    },

    logout() {
      this.$store
        .dispatch("user/logout")
        .then(() => {
          this.drawer = false;
          this.$router.push("/login");
        })
        .catch((error) => {
          console.log("Logout failed", error);
        });
    },

    goToReportsExport() {
      this.reportsTab = 1;
      this.$router.push({ name: "reports" });
    },
  },
};
</script>
