const state = {
  dataTableOptions: {
    groupBy: [],
    groupDesc: [],
    itemsPerPage: 25,
    multiSort: true,
    mustSort: false,
    page: 1,
    sortBy: ["node.tenant.tenantName", "node.reportName"],
    sortDesc: [false, false],
  },
  search: null,
  selectedReportGroup: null,
  selectedTenant: null,
};

const mutations = {
  SET_DATA_TABLE_OPTIONS(state, payload) {
    state.dataTableOptions = payload;
  },

  SET_SEARCH(state, payload) {
    state.search = payload;
  },

  SET_SELECTED_REPORT_GROUP(state, payload) {
    state.selectedReportGroup = payload;
  },
  
  SET_SELECTED_TENANT(state, payload) {
    state.selectedTenant = payload;
  },
};

const actions = {
  setDataTableOptions(context, payload) {
    context.commit("SET_DATA_TABLE_OPTIONS", payload);
  },

  setSearch(context, payload) {
    context.commit("SET_SEARCH", payload);
  },

  setSelectedReportGroup(context, payload) {
    context.commit("SET_SELECTED_REPORT_GROUP", payload);
  },
  
  setSelectedTenant(context, payload) {
    context.commit("SET_SELECTED_TENANT", payload);
  },
};

const getters = {
  getDataTableOptions(state) {
    return state.dataTableOptions;
  },

  getSearch(state) {
    return state.search;
  },

  getSelectedReportGroup(state) {
    return state.selectedReportGroup;
  },
  
  getSelectedTenant(state) {
    return state.selectedTenant;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
