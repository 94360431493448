<template>
  <BaseTabs
    :breadcrumbs="breadcrumbs"
    :tab="tab"
    :tabs="tabs"
    v-on:updateTab="onUpdateTab"
  />
</template>

<style scoped></style>

<script>
import BaseTabs from "@/components/base/BaseTabs.vue";
import GroupsList from "@/components/users/GroupsList.vue";
import helper from "@/utils/helper.js";
import TenantsList from "@/components/users/TenantsList.vue";
import ThemesList from "@/components/users/ThemesList.vue";
import UsersList from "@/components/users/UsersList.vue";

export default {
  name: "users",
  components: {
    BaseTabs,
    GroupsList,
    TenantsList,
    ThemesList,
    UsersList,
  },

  data: function () {
    return {
      breadcrumbs: [
        {
          text: this.$t("label.home"),
          disable: false,
          href: "/#/",
        },
        {
          text: this.$tc("label.user", 2),
          disabled: true,
        },
      ],

      tabs: [
        {
          component: UsersList,
          label: this.$tc("label.user", 2),
          name: "UsersList",
          visible: helper.hasPermission("users.change_customuser"),
        },
        {
          component: TenantsList,
          label: this.$tc("label.tenant", 2),
          name: "TenantsList",
          visible: this.$store.state.user.me.isSuperuser,
        },
        {
          component: GroupsList,
          label: this.$tc("label.group", 2),
          name: "GroupsList",
          visible: this.$store.state.user.me.isSuperuser,
        },
        {
          component: ThemesList,
          label: this.$tc("label.theme", 2),
          name: "ThemesList",
          visible: this.$store.state.user.me.isSuperuser,
        },
      ].filter((tab) => tab.visible !== false),
    };
  },

  computed: {
    me() {
      return this.$store.state.user.me;
    },

    tab: {
      get() {
        return this.$store.getters["users/getTab"];
      },
      set(value) {
        this.$store.dispatch("users/setTab", value);
      },
    },
  },

  watch: {},

  created() {
    this.hasPermission = helper.hasPermission;
  },

  mounted() {},

  methods: {
    onUpdateTab(item) {
      this.tab = item;
    },
  },
};
</script>
