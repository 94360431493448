<template>
  <BaseTabs
    :breadcrumbs="breadcrumbs"
    :tab="tab"
    :tabs="tabs"
    v-on:updateTab="onUpdateTab"
  />
</template>

<style scoped></style>

<script>
import BaseTabs from "@/components/base/BaseTabs.vue";
import ExportsList from "@/components/reports/ExportsList.vue";
import helper from "@/utils/helper.js";
import ReportGroupsList from "@/components/reports/ReportGroupsList.vue";
import ReportsList from "@/components/reports/ReportsList.vue";
import UsermappingsList from "@/components/reports/UsermappingsList.vue";

export default {
  name: "reports",
  components: {
    BaseTabs,
    ExportsList,
    ReportGroupsList,
    ReportsList,
    UsermappingsList,
  },

  data: function () {
    return {
      breadcrumbs: [
        {
          text: this.$t("reports.breadcrumbs.home"),
          disable: false,
          href: "/#/",
        },
        {
          text: this.$t("reports.breadcrumbs.reports"),
          disabled: true,
        },
      ],

      tabs: [
        {
          component: ReportsList,
          label: this.$t("reports.title"),
          name: "ReportsList",
          showButtons: this.$store.state.user.me.isSuperuser,
        },
        {
          component: ExportsList,
          label: this.$tc("label.export", 2),
          name: "ExportsList",
          showButtons: false,
        },
        {
          component: UsermappingsList,
          label: this.$t("reportsSettings.usermappings.title"),
          name: "UsermappingsList",
          visible: helper.hasPermission("dashboards.view_usermapping"),
        },
        {
          component: ReportGroupsList,
          label: this.$t("reportsSettings.reportGroups.title"),
          name: "ReportGroupsList",
          visible: this.$store.state.user.me.isSuperuser,
        },
      ].filter((tab) => tab.visible !== false),
    };
  },

  computed: {
    me() {
      return this.$store.state.user.me;
    },

    tab: {
      get() {
        return this.$store.getters["reports/getTab"];
      },
      set(value) {
        this.$store.dispatch("reports/setTab", value);
      },
    },
  },

  watch: {},

  created() {
    this.hasPermission = helper.hasPermission;
  },

  mounted() {},

  methods: {
    onUpdateTab(item) {
      this.tab = item;
    },
  },
};
</script>
