const state = {
	tab: 0,
};

const mutations = {
	SET_TAB(state, payload) {
		state.tab = payload;
	},
};

const actions = {
	setTab(context, payload) {
		context.commit("SET_TAB", payload);
	},
};

const getters = {
	getTab(state) {
		return state.tab;
	},
};

export default {
	namespaced: true,
	state,
	getters,
	mutations,
	actions,
};
