<template>
  <v-layout>
    <v-container
      class="ma-0 pa-0 align-start"
      fill-height
      fluid
      v-if="$vuetify.breakpoint.mdAndUp"
    >
      <v-navigation-drawer
        :width="pageDrawerWidth"
        class="ma-0 pa-0"
        height="93vh"
        v-if="pageDrawer"
      >
        <v-list-item>
          <v-list-item-content>
            <v-list-item-title class="text-h6">
              {{ $t("button.pageBtn") }}
            </v-list-item-title>
          </v-list-item-content>

          <v-btn @click="pageDrawer = false" icon>
            <v-icon>chevron_left</v-icon>
          </v-btn>
        </v-list-item>

        <v-progress-linear
          indeterminate
          rounded
          v-if="reportPages.length == 0"
        ></v-progress-linear>

        <v-list dense nav>
          <v-list-item-group
            active-class="primary--text text--accent-4"
            mandatory
            v-model="selectedPage"
          >
            <v-list-item
              :key="page.name"
              :value="page.name"
              @click="setSelectedPage(page.name)"
              v-for="page in reportPages"
            >
              <v-list-item-content>
                <v-list-item-title>{{ page.displayName }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list-item-group>
        </v-list>
      </v-navigation-drawer>

      <v-navigation-drawer
        :width="pageDrawerWidth"
        class="ma-0 pa-0"
        height="93vh"
        v-if="!pageDrawer"
      >
        <v-btn @click="pageDrawer = true" class="mt-2" icon>
          <v-icon>chevron_right</v-icon>
        </v-btn>

        <div class="mt-7 text-h6" style="transform: rotate(-90deg)">
          {{ $t("button.pageBtn") }}
        </div>
      </v-navigation-drawer>

      <v-container
        :style="containerStyle"
        class="ma-0 pa-0 align-start"
        fill-height
        fluid
      >
        <v-toolbar class="ma-0 pa-0" dense>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                @click="$router.push({ name: 'reports' })"
                class="mr-2"
                color="primary"
                rounded
                small
                text
                v-bind="attrs"
                v-on="on"
              >
                <v-icon outline> arrow_back </v-icon>
              </v-btn>
            </template>
            <span>{{ $t("button.backBtn") }}</span>
          </v-tooltip>

          <v-btn
            :disabled="isEditMode"
            @click="fullscreenReport()"
            class="mr-2"
            color="primary"
            outlined
            rounded
            small
          >
            <v-icon left> fullscreen </v-icon
            >{{ $t("reportDetail.reportBtn.fullscreen") }}
          </v-btn>

          <v-btn
            :disabled="isEditMode"
            @click="printReport()"
            class="mr-2"
            color="primary"
            outlined
            rounded
            small
          >
            <v-icon left> print </v-icon
            >{{ $t("reportDetail.reportBtn.print") }}
          </v-btn>

          <v-btn
            :disabled="reloadIsDisabled || isEditMode"
            @click="reloadReport()"
            class="mr-2"
            color="primary"
            outlined
            rounded
            small
          >
            <v-icon left> refresh </v-icon
            >{{ $t("reportDetail.reportBtn.reload") }}
          </v-btn>

          <v-btn
            :disabled="isEditMode"
            @click="editReport()"
            class="mr-2"
            color="primary"
            outlined
            rounded
            small
            v-if="
              (hasPermission('dashboards.change_embedded_report') &&
                report.isEditable) ||
              me.isSuperuser
            "
          >
            <v-icon left outline> edit </v-icon
            >{{ $t("reportDetail.reportBtn.edit") }}
          </v-btn>

          <v-btn
            :disabled="!isEditMode"
            @click="saveReport()"
            class="mr-2"
            color="primary"
            outlined
            rounded
            small
            v-if="
              (hasPermission('dashboards.change_embedded_report') &&
                report.isEditable) ||
              me.isSuperuser
            "
          >
            <v-icon left outline> save </v-icon
            >{{ $t("reportDetail.reportBtn.save") }}
          </v-btn>

          <v-btn
            :disabled="!isEditMode"
            @click="viewReport()"
            class="mr-2"
            color="primary"
            outlined
            rounded
            small
            v-if="
              (hasPermission('dashboards.change_embedded_report') &&
                report.isEditable) ||
              me.isSuperuser
            "
          >
            <v-icon left outline> remove_red_eye </v-icon
            >{{ $t("reportDetail.reportBtn.view") }}
          </v-btn>

          <v-text-field
            :disabled="isEditMode"
            :loading="isSaving"
            @click:append="saveReportFilters"
            @keydown.enter="saveReportFilters"
            append-icon="save"
            class="shrink mr-2"
            clearable
            dense
            hide-details
            outlined
            style="width: 200px"
            v-model="inputFilterName"
            :label="`${$tc('label.filter', 1)} ${$tc('label.name', 1)}`"
          ></v-text-field>

          <v-select
            :disabled="isEditMode"
            :items="userReportFilters.edges"
            :label="`${$t('label.select')} ${$tc('label.filter', 1)}`"
            :loading="$apollo.queries.userReportFilters.loading"
            @input="setSelectedFilter"
            class="shrink mr-2"
            clearable
            dense
            hide-details
            item-key="node.id"
            item-text="node.filterName"
            outlined
            return-object
            style="width: 200px"
            v-model="selectedUserReportFilter"
          >
            <template v-slot:item="{ attrs, item, on }">
              <v-list-item v-bind="attrs" v-on="on">
                <v-list-item-content>
                  <v-list-item-title>
                    <v-row align="center" no-gutters>
                      <span>{{ item.node.filterName }}</span>
                      <v-spacer></v-spacer>
                      <v-chip
                        :color="
                          item.node.type === 'SHARED'
                            ? 'info'
                            : item.node.type === 'ORGANIZATION'
                            ? 'primary'
                            : ''
                        "
                        class="ml-2"
                        small
                        >{{ item.node.type }}</v-chip
                      >
                    </v-row>
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </template>
          </v-select>

          <v-spacer />

          <v-tooltip bottom>
            <template v-slot:activator="{ attrs, on }">
              <v-btn
                :disabled="
                  isEditMode || taskResultExtensionFileStatus == 'STARTED'
                "
                :class="['mr-2']"
                @click="exportDrawer = true"
                color="primary"
                rounded
                small
                v-bind="attrs"
                v-on="on"
              >
                <v-icon outline> file_download </v-icon>
              </v-btn>
            </template>
            <span>{{ $tc("label.export", 1) }}</span>
          </v-tooltip>

          <v-tooltip bottom>
            <template v-slot:activator="{ attrs, on }">
              <v-btn
                :disabled="isEditMode"
                @click="reportFiltersDrawer = true"
                color="primary"
                rounded
                small
                v-bind="attrs"
                v-on="on"
              >
                <v-icon outline> filter_list </v-icon>
              </v-btn>
            </template>
            <span>{{ $tc("label.filter", 2) }}</span>
          </v-tooltip>
        </v-toolbar>

        <div id="reportContainer" :style="iFrameStyle" />
      </v-container>
    </v-container>

    <v-container class="ma-0 pa-0 align-start" fill-height fluid v-else>
      <v-row class="ma-0 pa-0">
        <v-bottom-navigation v-if="!disableAppBar">
          <v-btn
            class="navigation-btn"
            small
            @click="$router.push({ name: 'reports' })"
          >
            <span>{{ $t("button.backBtn") }}</span>

            <v-icon>arrow_back</v-icon>
          </v-btn>

          <v-menu offset-y>
            <template v-slot:activator="{ attrs, on }">
              <v-btn class="navigation-btn" small v-bind="attrs" v-on="on">
                <span>{{ $t("button.pageBtn") }}</span>
                <v-icon>expand_more</v-icon>
              </v-btn>
            </template>
            <v-list dense nav>
              <v-list-item-group
                active-class="primary--text text--accent-4"
                mandatory
                v-model="selectedPage"
              >
                <v-list-item
                  :key="page.name"
                  :value="page.name"
                  @click="setSelectedPage(page.name)"
                  v-for="page in reportPages"
                >
                  <v-list-item-content>
                    <v-list-item-title>{{
                      page.displayName
                    }}</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-list-item-group>
            </v-list>
          </v-menu>

          <v-btn class="navigation-btn" small @click="reloadReport()">
            <span>{{ $t("reportDetail.reportBtn.reload") }}</span>

            <v-icon>refresh</v-icon>
          </v-btn>

          <v-btn class="navigation-btn" small @click="fullscreenMobile()">
            <span>{{ $t("reportDetail.reportBtn.fullscreen") }}</span>

            <v-icon>fullscreen</v-icon>
          </v-btn>

          <v-btn
            @click="showSecondBar = !showSecondBar"
            class="navigation-btn"
            small
          >
            <span>{{ $t("button.moreBtn") }}</span>

            <v-icon>{{ showSecondBar ? "unfold_less" : "more_vert" }}</v-icon>
          </v-btn>
        </v-bottom-navigation>

        <v-col class="ma-0 pa-0" cols="12" v-else>
          <v-system-bar color="primary" dark lights-out window>
            <span>{{ report.reportName }}</span>
            <v-spacer></v-spacer>
            <v-icon @click="fullscreenMobileExit()">fullscreen_exit</v-icon>
          </v-system-bar></v-col
        >

        <v-bottom-navigation v-if="!disableAppBar && showSecondBar">
          <v-menu offset-y>
            <template v-slot:activator="{ attrs, on }">
              <v-btn class="navigation-btn" small v-bind="attrs" v-on="on">
                <span>{{ $tc("label.filter", 2) }}</span>
                <v-icon>filter_list</v-icon>
              </v-btn>
            </template>
            <v-list dense nav>
              <v-list-item-group
                active-class="primary--text text--accent-4"
                mandatory
                v-model="selectedUserReportFilter"
              >
                <v-list-item
                  :key="item.node.id"
                  :value="item.node.filterJson"
                  @click="setSelectedFilter(item)"
                  v-for="item in userReportFilters.edges"
                >
                  <v-list-item-content>
                    <v-list-item-title>
                      <v-row align="center" no-gutters>
                        <span>{{ item.node.filterName }}</span>
                        <v-spacer></v-spacer>
                        <v-chip
                          :color="
                            item.node.type === 'SHARED'
                              ? 'info'
                              : item.node.type === 'ORGANIZATION'
                              ? 'primary'
                              : ''
                          "
                          class="ml-2"
                          small
                          >{{ item.node.type }}</v-chip
                        >
                      </v-row>
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-list-item-group>
            </v-list>
          </v-menu>
        </v-bottom-navigation>

        <v-col class="ma-0 pa-0" cols="12">
          <v-container class="ma-0 pa-0" fluid>
            <div :style="iFrameStyle" id="reportContainer" />
          </v-container>
        </v-col>
      </v-row>
    </v-container>

    <v-navigation-drawer
      :width="reportFiltersDrawerWidth"
      app
      right
      stateless
      style="top: 45px; z-index: 3"
      temporary
      v-model="reportFiltersDrawer"
    >
      <ReportFiltersDrawer
        :drawer.sync="reportFiltersDrawer"
        :reportNodeId.sync="this.$route.params.reportNodeId"
        ref="reportFiltersDrawer"
      ></ReportFiltersDrawer>
    </v-navigation-drawer>

    <v-navigation-drawer
      :width="reportFiltersDrawerWidth"
      app
      right
      stateless
      style="top: 45px; z-index: 3"
      temporary
      v-model="exportDrawer"
    >
      <ExportDrawer
        :drawer.sync="exportDrawer"
        :reportPages.sync="reportPages"
        ref="exportDrawer"
      ></ExportDrawer>
    </v-navigation-drawer>
  </v-layout>
</template>

<script>
import * as pbi from "powerbi-client";
import ExportDrawer from "@/components/reports/ExportDrawer.vue";
import gql from "graphql-tag";
import helper from "@/utils/helper.js";
import ReportFiltersDrawer from "@/components/reports/ReportFiltersDrawer.vue";
import rules from "@/utils/rules.js";

export default {
  name: "report-detail",
  components: {
    ExportDrawer,
    ReportFiltersDrawer,
  },

  apollo: {
    pbiEmbedToken: {
      query: gql`
        query pbiEmbedToken($reportNodeId: ID!) {
          pbiEmbedToken(reportNodeId: $reportNodeId) {
            expiration
            token
            tokenId
          }
        }
      `,
      variables() {
        return {
          reportNodeId: this.$route.params.reportNodeId,
        };
      },
      fetchPolicy: "network-only",
      skip: true,
      update: (data) => data.pbiEmbedToken,
      result({ data }) {
        var expiration = Date.parse(data.pbiEmbedToken.expiration);

        var safetyInterval = 5 * 60 * 1000;
        var currentTime = Date.now();

        // time until token refresh in milliseconds
        var timeout = expiration - currentTime - safetyInterval;
        // var timeout = (expiration - currentTime - safetyInterval) / 100;

        // console.log(
        // 	"Report Embed Token will be updated in " +
        // 		timeout / 60000 +
        // 		" minutes."
        // );

        // set the refresh of the next token
        this.$apollo.queries.pbiEmbedToken.stopPolling();
        this.$apollo.queries.pbiEmbedToken.startPolling(timeout); //ms

        if (this.nrOfTokens == 0) {
          this.loadReport();
        } else {
          this.updateToken();
        }

        this.nrOfTokens++;
      },
      // Error handling
      error(error) {
        console.error("We've got an error!", error);
        this.error = error.graphQLErrors
          .map((error) => error.message)
          .join(", ");
      },
    },

    report: {
      query: gql`
        query report($reportNodeId: ID!) {
          report(id: $reportNodeId) {
            id
            reportName
            groupId
            reportId
            datasetId
            isBinded
            bindedGroupId
            bindedDatasetId
            pbiDateColumn
            pbiDateTable
            isEditable
            reportType
            tenant {
              tenantName
            }
          }
        }
      `,
      variables() {
        return {
          reportNodeId: this.$route.params.reportNodeId,
        };
      },
      fetchPolicy: "network-only",
      update: (data) => data.report,
      result() {
        this.setAppBarTitle();

        this.$apollo.queries.pbiEmbedToken.skip = false;
      },
    },

    userReportFilters: {
      query: gql`
        query userReportFilters(
          $reportNodeId: ID!
          $allUserReportFilters: Boolean!
        ) {
          userReportFilters(
            report_Id: $reportNodeId
            allUserReportFilters: $allUserReportFilters
            orderBy: ["filterName"]
          ) {
            edges {
              node {
                id
                filterJson
                filterName
                type
              }
            }
          }
        }
      `,
      variables() {
        return {
          reportNodeId: this.$route.params.reportNodeId,
          allUserReportFilters: true,
        };
      },
      update: (data) => data.userReportFilters,
    },
  },

  data() {
    return {
      error: null,
      exportDrawer: false,
      inputFilterName: null,
      isEditMode: false,
      isInitialLoad: true,
      isSaving: false,
      nrOfTokens: 0,
      pageDrawer: true,
      pbiEmbedToken: {},
      reloadIsDisabled: true,
      report: {
        order: {
          remainingTrialPeriod: null,
        },
      },
      reportFiltersDrawer: false,
      reportPages: [],
      selectedPage: null,
      selectedTimePeriod: {},
      selectedUserReportFilter: {},
      showSecondBar: false,
      userReportFilters: {},
    };
  },

  computed: {
    appBarTitle: {
      get() {
        return this.$store.getters["app/getAppBarTitle"];
      },
      set(value) {
        this.$store.dispatch("app/setAppBarTitle", value);
      },
    },

    disableAppBar: {
      get() {
        return this.$store.getters["app/getDisableAppBar"];
      },
      set(value) {
        this.$store.dispatch("app/setDisableAppBar", value);
      },
    },

    containerStyle() {
      var width = 100 - Number(this.pageDrawerWidth.replace("%", ""));

      return "width: " + width + "%;";
    },

    iFrameStyle() {
      var breakpointName = this.$vuetify.breakpoint.name;

      if (breakpointName == "xs" && !this.disableAppBar) {
        return "width: 100%; height: 86vh; border-style: none;";
      } else if (breakpointName == "xs" && this.disableAppBar) {
        return "width: 100%; height: 95vh; border-style: none;";
      } else if (breakpointName == "sm" && !this.disableAppBar) {
        return "width: 100%; height: 71vh; border-style: none;";
      } else if (breakpointName == "sm" && this.disableAppBar) {
        return "width: 100%; height: 90vh; border-style: none;";
      } else if (breakpointName == "xl") {
        return "width: 100%; height: 88vh; border-style: none;";
      } else {
        return "width: 100%; height: 86vh; border-style: none;";
      }
    },

    me() {
      return this.$store.state.user.me;
    },

    pageDrawerWidth() {
      var breakpoint = this.$vuetify.breakpoint.name;
      if (this.pageDrawer) {
        switch (breakpoint) {
          case "xl":
            return "13%";
          default:
            return "15%";
        }
      } else {
        switch (breakpoint) {
          case "xl":
            return "2%";
          default:
            return "2.5%";
        }
      }
    },

    reportFiltersDrawerWidth() {
      switch (this.$vuetify.breakpoint.name) {
        case "lg":
          return "35%";
        case "xl":
          return "25%";
        default:
          return "100%";
      }
    },

    taskResultExtensionFileStatus() {
      return this.$store.state.tasks.taskResultExtensionFileStatus;
    },
  },
  watch: {},
  created() {
    this.hasPermission = helper.hasPermission;

    this.rules = rules;

    this.logPageView();
  },
  mounted() {
    window.addEventListener("orientationchange", this.handleOrientationChange);

    // set event listeners for all possible fullscreen changes
    [
      "fullscreenchange",
      "webkitfullscreenchange",
      "mozfullscreenchange",
      "msfullscreenchange",
    ].forEach((eventType) =>
      document.addEventListener(eventType, this.handleFullscreenChange, false)
    );
  },
  methods: {
    editReport() {
      this.isEditMode = true;
      this.pageDrawer = false;

      let report = this.getReportContainer();

      // Displays the report in edit mode
      report.switchMode("edit");
    },

    fullscreenMobile() {
      this.disableAppBar = true;
    },

    fullscreenMobileExit() {
      this.disableAppBar = false;
    },

    fullscreenReport() {
      let report = this.getReportContainer();

      // Displays the report in full screen mode.
      report.fullscreen();
    },

    async getActivePage() {
      let report = this.getReportContainer();

      // Get current report state
      let getActivePage = new Promise((resolve, reject) => {
        report
          .getActivePage()
          .then((response) => {
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          });
      });

      let activePage = await getActivePage;

      this.selectedPage = activePage.name;
    },

    getReportContainer() {
      // Get a reference to the embedded report HTML element
      var embedContainer = document.getElementById("reportContainer");

      // Get a reference to the embedded report.
      var report = powerbi.get(embedContainer);

      return report;
    },

    async getReportPages() {
      let report = this.getReportContainer();

      // Get current report state
      let getReportPages = new Promise((resolve, reject) => {
        report
          .getPages()
          .then((response) => {
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          });
      });

      let reportPages = await getReportPages;

      this.reportPages = reportPages.filter(function (reportpage) {
        return reportpage.visibility == 0;
      });

      this.getActivePage();
    },

    goToReportDetailSettings(reportNodeId) {
      this.$router.push({
        name: "report-detail-settings",
        params: { id: reportNodeId },
      });
    },

    handleFullscreenChange() {
      // Handles changes to fullscreen mode and updates nav bar in report for non mobile viewers
      if (!this.$vuetify.breakpoint.mobile) {
        if (
          !document.webkitIsFullScreen &&
          !document.mozFullScreen &&
          !document.msFullscreenElement
        ) {
          var newSettings = {
            navContentPaneEnabled: false,
          };

          let report = this.getReportContainer();

          report.updateSettings(newSettings).catch(function (errors) {
            console.log(errors);
          });

          // Get the active page to keep the pages pane in sync
          this.getActivePage();
        } else {
          var newSettings = {
            navContentPaneEnabled: true,
          };

          let report = this.getReportContainer();

          report.updateSettings(newSettings).catch(function (errors) {
            console.log(errors);
          });
        }
      }
    },

    handleOrientationChange() {
      const orientation = window.screen.orientation.type;
      if (
        this.$vuetify.breakpoint.xsOnly &&
        orientation === "portrait-primary"
      ) {
        // portrait mode
        var models = pbi.models;

        var newSettings = {
          filterPaneEnabled: true,
          navContentPaneEnabled: false,
          layoutType: models.LayoutType.MobilePortrait,
        };

        let report = this.getReportContainer();

        // Update the settings by passing in the new settings you have configured.
        report.updateSettings(newSettings).catch(function (errors) {
          console.log(errors);
        });
      } else if (orientation === "landscape-primary") {
        // portrait mode
        var models = pbi.models;

        var newSettings = {
          filterPaneEnabled: true,
          navContentPaneEnabled: false,
          layoutType: models.LayoutType.MobileLandscape,
        };

        let report = this.getReportContainer();

        // Update the settings by passing in the new settings you have configured.
        report.updateSettings(newSettings).catch(function (errors) {
          console.log(errors);
        });
      }
    },

    loadReport() {
      const self = this;

      var reportId = this.report.reportId;
      var datasetId = this.report.datasetId;
      var isBinded = this.report.isBinded;
      var bindedDatasetId = this.report.bindedDatasetId;

      var models = pbi.models;

      // different settings for mobile and desktop
      var settings = {};
      if (this.$vuetify.breakpoint.mobile && !this.$vuetify.breakpoint.xsOnly) {
        settings = {
          settings: {
            filterPaneEnabled: true,
            navContentPaneEnabled: false,
          },
        };
      } else if (this.$vuetify.breakpoint.xsOnly) {
        settings = {
          settings: {
            filterPaneEnabled: false,
            navContentPaneEnabled: false,
            layoutType: models.LayoutType.MobilePortrait,
          },
        };
      } else {
        settings = {
          settings: {
            filterPaneEnabled: true,
            navContentPaneEnabled: false,
          },
        };
      }

      var locale = this.$i18n.locale;
      var language = "nl-NL";
      if (locale == "en") {
        language = "en-US";
      } else if (locale == "de") {
        language = "de-DE";
      }

      settings.settings.localeSettings = {
        language: language,
      };

      // if binded dataset then use that else normal dataset
      var datasetIdToLoad = null;
      if (isBinded) {
        datasetIdToLoad = bindedDatasetId;
      } else {
        datasetIdToLoad = datasetId;
      }

      // if there is an initial bookmark set the state
      var bookmark = null;
      if (localStorage.getItem(this.$route.params.reportNodeId) !== null) {
        bookmark = {
          bookmark: {
            state: JSON.parse(
              localStorage.getItem(this.$route.params.reportNodeId)
            ).state,
          },
        };
      }

      var config = {
        type: "report",
        id: reportId,
        embedUrl: "https://app.powerbi.com/reportEmbed",
        tokenType: models.TokenType.Embed,
        permissions: models.Permissions.All,
        viewMode: models.ViewMode.View,
        accessToken: this.pbiEmbedToken.token,
        datasetBinding: {
          // If the binded dataset is empty then use the dataset otherwise use the binded dataset
          datasetId: datasetIdToLoad, // The wanted dataset id
        },
      };

      var embedConfiguration = Object.assign(config, settings, bookmark);

      var element = document.getElementById("reportContainer");
      var report = powerbi.embed(element, embedConfiguration);

      // Report.off removes a given event handler if it exists.
      report.off("loaded");
      report.off("rendered");

      // Report.on will add an event handler which prints to Log window.
      report.on("loaded", function () {
        self.getReportPages();

        if (self.$vuetify.breakpoint.xsOnly) {
          self.handleOrientationChange();
        }
      });

      // report.on will add an event handler which prints to Log window.
      report.on("rendered", function () {
        if (bookmark !== null) {
          self.reloadIsDisabled = false;
        }

        if (!self.isInitialLoad) {
          self.saveCurrentReportState();
          self.reloadIsDisabled = false;
        } else {
          self.isInitialLoad = false;
        }
      });

      // After embedded delete the border of the iframe
      var iframe = document.getElementsByTagName("iframe")[0];
      iframe.setAttribute(
        "style",
        "width: 100%; height: 100%; border-style: none;"
      );
    },

    logPageView() {
      // prepare api call payload
      var payload = {
        logType: "pageview",
        refType: "report",
        refNodeId: this.$route.params.reportNodeId,
      };

      // new
      this.$apollo
        .mutate({
          // Query
          mutation: gql`
            mutation createLog($input: CreateLogInput!) {
              createLog(input: $input) {
                log {
                  id
                }
              }
            }
          `,
          // Parameters
          variables: {
            input: payload,
          },
        })
        .then((response) => {})
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {});
    },

    printReport() {
      let report = this.getReportContainer();

      // Trigger the print dialog for your browser.
      report.print().catch(function (errors) {
        Log.log(errors);
      });
    },

    reloadReport() {
      localStorage.removeItem(this.$route.params.reportNodeId);

      this.reloadIsDisabled = true;
      this.isInitialLoad = true;

      this.loadReport();

      // Set report period selection to all
      this.togglePeriodFilter = 0;

      // Set filter name input to null
      this.inputFilterName = null;
      if (this.$refs.form) {
        this.$refs.form.reset();
      }

      // Deselect the user report filter
      this.selectedUserReportFilter = {};
    },

    async saveCurrentReportState() {
      let report = this.getReportContainer();

      // Get current report state
      let bookmarkCapturePromise = new Promise((resolve, reject) => {
        report.bookmarksManager
          .capture()
          .then((response) => {
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          });
      });

      let bookmarkCapture = await bookmarkCapturePromise;

      localStorage.setItem(
        this.$route.params.reportNodeId,
        JSON.stringify(bookmarkCapture)
      );
    },

    saveReport() {
      this.isEditMode = false;
      this.pageDrawer = true;

      let report = this.getReportContainer();

      // Save report
      report.save();

      this.viewReport();

      this.getReportPages();
    },

    saveReportFilters() {
      let report = this.getReportContainer();

      if (!this.inputFilterName) {
        // show snackbar
        const payload = {
          color: "error",
          message: `Filter name is empty`,
        };
        this.$store.dispatch("snackbar/showMessage", payload);
      } else {
        report.getFilters().then((filters) => {
          // set form state
          this.error = null;
          this.isSaving = true;

          // prepare api call payload
          var payload = {
            reportNodeId: this.$route.params.reportNodeId,
            filterJson: filters,
            filterName: this.inputFilterName,
          };

          // new
          this.$apollo
            .mutate({
              // Query
              mutation: gql`
                mutation createUserReportFilter(
                  $input: CreateUserReportFilterInput!
                ) {
                  createUserReportFilter(input: $input) {
                    userReportFilter {
                      id
                      filterJson
                      filterName
                      type
                    }
                  }
                }
              `,
              // Parameters
              variables: {
                input: payload,
              },
            })
            .then((response) => {
              this.$emit("item-created", response);

              this.error = null;
              this.isSaving = false;
              if (this.$refs.form) {
                this.$refs.form.reset();
              }

              // show snackbar
              const payload = {
                color: "success",
                message: `Filter successfully added!`,
              };
              this.$store.dispatch("snackbar/showMessage", payload);

              this.inputFilterName = null;
              this.$apollo.queries.userReportFilters.refetch();
            })
            .catch((error) => {
              console.log(error);

              this.error = error.graphQLErrors
                .map((error) => error.message)
                .join(", ");
              this.isSaving = false;

              // show snackbar
              const payload = {
                color: "error",
                message: this.error,
              };
              this.$store.dispatch("snackbar/showMessage", payload);
            })
            .finally(() => {});
        });
      }
    },

    setAppBarTitle() {
      const title =
        this.report.tenant.tenantName + " - " + this.report.reportName;

      this.appBarTitle = title;

      document.title = `${title} | Insurance Data`;
    },

    setFilterAll() {
      var pbiDateTable = this.report.pbiDateTable;

      let report = this.getReportContainer();

      // First get all the report filters then delete the filter on the date table and add the new constructed filter to the array
      report.getFilters().then(function (allTargetFilters) {
        // Delete the old filter on the date column
        var filteredAllTargetFilters = allTargetFilters.filter(function (
          allFilters
        ) {
          return allFilters.target.table !== pbiDateTable;
        });
        report.setFilters(filteredAllTargetFilters);
      });
    },

    setSelectedFilter(item) {
      if (item) {
        var filter = JSON.parse(item.node.filterJson);

        let report = this.getReportContainer();

        report.setFilters(filter);
      }
    },

    setSelectedPage(val) {
      let report = this.getReportContainer();

      report.setPage(val);
    },

    async updateToken() {
      let report = this.getReportContainer();

      // Set the new access token
      await report.setAccessToken(this.pbiEmbedToken.token);
    },

    viewReport() {
      this.isEditMode = false;
      this.pageDrawer = true;

      let report = this.getReportContainer();

      // Displays the report in view mode
      report.switchMode("view");

      this.getActivePage();
    },
  },
};
</script>
