import app from "@/store/modules/app";
import exportsList from "@/store/modules/reports/exportsList";
import groupDetail from "@/store/modules/users/groupDetail";
import groupsList from "@/store/modules/users/groupsList";
import reportGroupsList from "@/store/modules/reports/reportGroupsList";
import reports from "@/store/modules/reports/reports";
import reportsList from "@/store/modules/reports/reportsList";
import snackbar from "@/store/modules/snackbar";
import tasks from "@/store/modules/tasks/tasks";
import tenantsList from "@/store/modules/users/tenantsList";
import themesList from "@/store/modules/users/themesList";
import user from "@/store/modules/user";
import usermappingsList from "@/store/modules/reports/usermappingsList";
import users from "@/store/modules/users/users";
import usersList from "@/store/modules/users/usersList";

import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    app,
    exportsList,
    groupDetail,
    groupsList,
    reportGroupsList,
    reports,
    reportsList,
    snackbar,
    tasks,
    tenantsList,
    themesList,
    user,
    usermappingsList,
    users,
    usersList,
  },
});
