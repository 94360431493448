<template>
  <v-row justify="center">
    <v-dialog v-model="dialog" persistent max-width="1100px" scrollable>
      <v-card>
        <v-alert v-if="error" tile type="error">{{ error }}</v-alert>

        <v-card-title class="primary white--text">
          {{ formTitle }}
          <v-spacer />
          <v-btn icon dark @click="closeMainDialog">
            <v-icon>close</v-icon>
          </v-btn>
        </v-card-title>

        <v-card-text class="pa-0">
          <v-stepper v-model="step" vertical>
            <!-- Step 1: select group -->
            <v-stepper-step step="1" :complete="step > 1">
              <span v-if="selectedGroup === null">{{
                $t("reportAddDialog.stepper.step1.title")
              }}</span>
              <span
                v-if="selectedGroup"
                class="grey--text text--darken-4 mb-4"
                >{{ selectedGroup ? selectedGroup.name : "" }}</span
              >
              <v-row v-if="selectedGroup" class="px-2">
                <v-chip class="mr-1 mb-1" small>
                  {{ selectedGroup ? selectedGroup.id : "" }}
                </v-chip>
                <v-spacer />
              </v-row>
            </v-stepper-step>
            <v-stepper-content step="1">
              <v-form ref="formStep1">
                <v-card flat>
                  <v-row>
                    <v-col cols="12">
                      <v-text-field
                        v-model="searchGroup"
                        append-outer-icon="search"
                        v-bind:label="$t('datatable.searchLabel')"
                        clearable
                        hide-details
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12">
                      <v-data-table
                        :headers="headersGroup"
                        :loading="$apollo.queries.pbiGroups.loading"
                        :items="pbiGroups?.edges"
                        item-key="id"
                        :search="searchGroup"
                        sort-by="name"
                      >
                        <template v-slot:[`item.action`]="{ item }">
                          <v-btn
                            small
                            outlined
                            rounded
                            color="primary"
                            @click="onGroupSelected(item)"
                            >{{ $t("button.selectBtn") }}</v-btn
                          >
                        </template>
                      </v-data-table>
                    </v-col>
                  </v-row>
                </v-card>
              </v-form>
            </v-stepper-content>

            <!-- Step 2: reports -->
            <v-stepper-step step="2" :complete="step > 2">
              <span>{{ $t("reportAddDialog.stepper.step2.title") }}</span>
              <span
                v-if="step > 2"
                class="mt-1 grey--text text--darken-2 subtitle-2"
                >{{ countSelectedReports }} reports</span
              >
            </v-stepper-step>
            <v-stepper-content step="2">
              <v-form ref="formStep2">
                <v-card flat>
                  <v-row>
                    <v-col cols="12">
                      <v-text-field
                        v-model="searchReport"
                        append-outer-icon="search"
                        v-bind:label="$t('datatable.searchLabel')"
                        clearable
                        hide-details
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12">
                      <v-data-table
                        :headers="headersReport"
                        :loading="$apollo.queries.pbiReports.loading"
                        :items="pbiReports?.edges"
                        :search="searchReport"
                        v-model="selectedReports"
                        item-key="id"
                        :show-select="'show-select'"
                        :sort-by="['reportType', 'name']"
                        :sort-desc="[true, false]"
                        multi-sort
                      >
                        <template v-slot:[`item.reportType`]="{ item }">
                          <v-tooltip bottom>
                            <template v-slot:activator="{ on }">
                              <v-icon
                                v-on="on"
                                v-if="item.reportType === 'PowerBIReport'"
                                color="primary"
                                >dashboard</v-icon
                              >
                              <v-icon
                                v-on="on"
                                v-else-if="
                                  item.reportType === 'PaginatedReport'
                                "
                                color="primary"
                                >layers</v-icon
                              >
                            </template>
                            <span v-if="item.reportType === 'PowerBIReport'">
                              {{ "Power BI Report" }}
                            </span>
                            <span
                              v-else-if="item.reportType === 'PaginatedReport'"
                            >
                              {{ "Paginated Report" }}
                            </span>
                          </v-tooltip>
                        </template>
                      </v-data-table>
                    </v-col>
                  </v-row>
                </v-card>

                <v-card-actions>
                  <v-spacer />
                  <v-btn
                    text
                    rounded
                    @click="
                      step = 1;
                      selectedGroup = null;
                    "
                    >{{ $t("button.previousBtn") }}</v-btn
                  >
                  <v-btn
                    outlined
                    rounded
                    color="primary"
                    @click="step2Next"
                    :disabled="selectedReports.length <= 0"
                    >{{ $t("button.nextBtn") }}</v-btn
                  >
                </v-card-actions>
              </v-form>
            </v-stepper-content>

            <!-- Step 3: report attributes -->
            <v-stepper-step step="3" :complete="step > 3">
              {{ $t("reportAddDialog.stepper.step3.title") }}
            </v-stepper-step>

            <v-stepper-content step="3">
              <v-form ref="formStep3">
                <v-card flat>
                  <v-row>
                    <v-col cols="12" md="6">
                      <v-autocomplete
                        v-model="tenantNodeId"
                        :items="tenants.edges"
                        item-text="node.tenantName"
                        item-value="node.id"
                        :loading="$apollo.queries.tenants.loading"
                        :rules="[rules.required]"
                        v-bind:label="
                          $t('reportAddDialog.stepper.step3.tenantLabel')
                        "
                        class="subtitle-2 mb-n5"
                        outlined
                      ></v-autocomplete>
                    </v-col>
                    <v-col cols="12" md="2">
                      <v-switch
                        v-model="isEditable"
                        v-bind:label="
                          $t('reportAddDialog.stepper.step3.isEditableLabel')
                        "
                        class="mt-3 my-0 py-0"
                        hide-details
                      ></v-switch>
                    </v-col>
                    <v-col cols="12" md="2">
                      <v-switch
                        v-model="isBinded"
                        v-bind:label="
                          $t('reportAddDialog.stepper.step3.isBindedLabel')
                        "
                        class="mt-3 my-0 py-0"
                        hide-details
                      ></v-switch>
                    </v-col>
                    <v-col cols="12" md="2">
                      <v-switch
                        v-model="isDirectQuery"
                        label="Direct Query"
                        class="mt-3 my-0 py-0"
                        hide-details
                      ></v-switch>
                    </v-col>
                    <v-col v-if="isBinded" cols="6">
                      <v-autocomplete
                        v-model="selectedBindedGroup"
                        :items="pbiGroups?.edges"
                        item-text="name"
                        item-value="id"
                        :loading="$apollo.queries.pbiGroups.loading"
                        :rules="[rules.required]"
                        v-bind:label="
                          $t('reportAddDialog.stepper.step3.bindedGroupLabel')
                        "
                        class="subtitle-2 mb-n5"
                        outlined
                      ></v-autocomplete>
                    </v-col>
                    <v-col v-if="isBinded" cols="6">
                      <v-autocomplete
                        v-model="selectedBindedDataset"
                        :items="pbiDatasets?.edges"
                        item-text="name"
                        item-value="id"
                        :loading="$apollo.queries.pbiDatasets.loading"
                        :rules="[rules.required]"
                        :disabled="!selectedBindedGroup"
                        v-bind:label="
                          $t('reportAddDialog.stepper.step3.bindedDatasetLabel')
                        "
                        class="subtitle-2 mb-n5"
                        outlined
                      ></v-autocomplete>
                    </v-col>
                    <v-col cols="4">
                      <v-autocomplete
                        v-model="reportGroupNodeId"
                        :items="reportGroups.edges"
                        item-text="node.reportGroupName"
                        item-value="node.id"
                        :loading="$apollo.queries.reportGroups.loading"
                        :rules="[]"
                        v-bind:label="
                          $t('reportAddDialog.stepper.step3.reportGroupLabel')
                        "
                        class="subtitle-2 mb-n5"
                        outlined
                      ></v-autocomplete>
                    </v-col>
                    <v-col cols="4">
                      <v-text-field
                        v-model="pbiDateTable"
                        :counter="30"
                        :rules="[rules.maxLength(30)]"
                        v-bind:label="
                          $t('reportAddDialog.stepper.step3.pbiDateTableLabel')
                        "
                        outlined
                        class="subtitle-2"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="4">
                      <v-text-field
                        v-model="pbiDateColumn"
                        :counter="30"
                        :rules="[rules.maxLength(30)]"
                        v-bind:label="
                          $t('reportAddDialog.stepper.step3.pbiDateColumnLabel')
                        "
                        outlined
                        class="subtitle-2"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                </v-card>

                <v-card-actions v-if="selectedGroup !== null" class="mt-10">
                  <v-spacer />
                  <v-btn text rounded @click="step = 2">{{
                    $t("button.previousBtn")
                  }}</v-btn>
                  <v-btn
                    outlined
                    rounded
                    color="primary"
                    :loading="isSaving"
                    :disabled="tenantNodeId === null"
                    @click="save"
                    >{{ $t("button.saveBtn") }}</v-btn
                  >
                </v-card-actions>
              </v-form>
            </v-stepper-content>
          </v-stepper>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import helper from "@/utils/helper.js";
import gql from "graphql-tag";

export default {
  name: "report-add-dialog",
  props: ["dialog", "object"],
  components: {},

  apollo: {
    pbiGroups: {
      query: gql`
        query pbiGroups {
          pbiGroups {
            edges {
              id
              name
            }
          }
        }
      `,
      variables() {},
      fetchPolicy: "cache-and-network",
      update: (data) => data.pbiGroups,
      skip: true,
    },

    pbiReports: {
      query: gql`
        query pbiReports($groupId: String!) {
          pbiReports(groupId: $groupId) {
            edges {
              id
              datasetId
              reportType
              name
            }
          }
        }
      `,
      variables() {
        return {
          groupId: this.selectedGroup?.id,
        };
      },
      fetchPolicy: "cache-and-network",
      update: (data) => data.pbiReports,
      skip() {
        return !this.selectedGroup;
      },
    },

    pbiDatasets: {
      query: gql`
        query pbiDatasets($groupId: String!) {
          pbiDatasets(groupId: $groupId) {
            edges {
              id
              name
            }
          }
        }
      `,
      variables() {
        return {
          groupId: this.selectedBindedGroup,
        };
      },
      fetchPolicy: "cache-and-network",
      update: (data) => data.pbiDatasets,
      skip() {
        return !this.selectedBindedGroup;
      },
    },

    tenants: {
      query: gql`
        query tenants {
          tenants {
            edges {
              node {
                id
                tenantName
              }
            }
          }
        }
      `,
      variables() {},
      fetchPolicy: "cache-and-network",
      update: (data) => data.tenants,
      skip() {
        return this.step != 3;
      },
    },

    reportGroups: {
      query: gql`
        query reportGroups {
          reportGroups {
            edges {
              node {
                id
                reportGroupName
              }
            }
          }
        }
      `,
      variables() {},
      fetchPolicy: "cache-and-network",
      update: (data) => data.reportGroups,
      skip() {
        return this.step != 3;
      },
    },
  },

  data() {
    return {
      countSelectedReports: null,
      error: null,
      isBinded: false,
      isDirectQuery: false,
      isEditable: false,
      isSaving: false,
      pbiDatasets: {},
      pbiDateColumn: null,
      pbiDateTable: null,
      pbiGroups: {},
      pbiReports: {},
      reportGroupNodeId: null,
      reportGroups: {},
      searchGroup: null,
      searchReport: null,
      selectedBindedDataset: null,
      selectedBindedGroup: null,
      selectedGroup: null,
      selectedReports: [],
      step: 1,
      tenantNodeId: null,
      tenants: {},

      headersGroup: [
        {
          text: this.$t(
            "reportAddDialog.stepper.step1.datatableHeaders.groupId"
          ),
          align: "left",
          sortable: true,
          value: "id",
        },
        {
          text: this.$t(
            "reportAddDialog.stepper.step1.datatableHeaders.groupName"
          ),
          align: "left",
          sortable: true,
          value: "name",
        },
        {
          text: this.$t("datatable.actionHeader"),
          align: "center",
          value: "action",
          sortable: false,
        },
      ],

      headersReport: [
        {
          text: "Report Type",
          align: "left",
          sortable: true,
          value: "reportType",
        },
        {
          text: this.$t(
            "reportAddDialog.stepper.step2.datatableHeaders.reportName"
          ),
          align: "left",
          sortable: true,
          value: "name",
        },
        {
          text: this.$t(
            "reportAddDialog.stepper.step2.datatableHeaders.reportId"
          ),
          align: "left",
          sortable: true,
          value: "id",
        },
      ],

      rules: {
        required: (v) => !helper.isEmpty(v) || "This field is required",
        minLength: (len) => (v) =>
          (v || "").length >= len ||
          `Invalid character length, required ${len}`,
        maxLength: (len) => (v) =>
          (v || "").length <= len || "Invalid character length, too long",
      },
    };
  },
  computed: {
    formTitle() {
      return this.object
        ? this.$t("reportAddDialog.title")
        : this.$t("reportAddDialog.title");
    },
  },
  watch: {
    // reset form when dialog open or close
    dialog(val) {
      if (val) {
        this.$apollo.queries.pbiGroups.skip = false;
        this.$apollo.queries.pbiGroups.refresh();
      }

      this.resetForm();
    },
  },
  created() {},
  methods: {
    step2Next() {
      if (!this.$refs.formStep2.validate()) {
        return;
      }
      this.countSelectedReports = this.selectedReports.length;
      this.step = 3;
    },

    onGroupSelected(item) {
      this.selectedGroup = null;
      this.selectedGroup = Object.assign({}, item);
      this.step = 2;
    },

    save() {
      if (!this.$refs.formStep3.validate()) {
        return;
      }

      this.isSaving = true;

      // we need only datasetId, reportId and the reportName
      const result = this.selectedReports.map((selectedReport) => ({
        datasetId: selectedReport.datasetId,
        reportId: selectedReport.id,
        reportName: selectedReport.name,
        reportType:
          selectedReport.reportType === "PowerBIReport"
            ? "POWERBIREPORT"
            : "PAGINATEDREPORT",
      }));

      // prepare api call payload
      var payload = {
        groupId: this.selectedGroup.id,
        tenantNodeId: this.tenantNodeId,
        isEditable: this.isEditable,
        pbiDateTable: this.pbiDateTable,
        pbiDateColumn: this.pbiDateColumn,
        reports: result,
        isBinded: this.isBinded,
        bindedGroupId: this.selectedBindedGroup,
        bindedDatasetId: this.selectedBindedDataset,
        isDirectQuery: this.isDirectQuery,
        reportGroupNodeId: this.reportGroupNodeId,
      };

      this.$apollo
        .mutate({
          // Query
          mutation: gql`
            mutation createReports($input: CreateReportsInput!) {
              createReports(input: $input) {
                report {
                  id
                  reportName
                }
              }
            }
          `,
          // Parameters
          variables: {
            input: payload,
          },
        })
        .then((response) => {
          this.$emit("changed", response);
          this.closeMainDialog();

          // show snackbar
          const payload = {
            color: "success",
            message: `Report successfully added`,
          };
          this.$store.dispatch("snackbar/showMessage", payload);
        })
        .catch((error) => {
          console.log(error);

          this.error = error.graphQLErrors
            .map((error) => error.message)
            .join(", ");
          this.isSaving = false;

          // show snackbar
          const payload = {
            color: "error",
            message: this.error,
          };
          this.$store.dispatch("snackbar/showMessage", payload);
        })
        .finally(() => {});
    },

    resetForm() {
      // reset form state
      this.error = false;
      this.isSaving = false;
      this.step = 1;
      this.searchGroup = null;
      this.selectedGroup = null;
      this.searchReport = null;
      this.selectedReports = [];
      this.tenantNodeId = null;
      this.isEditable = false;
      this.isBinded = false;
      this.selectedBindedGroup = null;
      this.selectedBindedDataset = null;
      this.pbiDateTable = null;
      this.pbiDateColumn = null;
      this.reportGroupNodeId = null;

      if (this.$refs.formStep3) {
        this.$refs.formStep3.resetValidation();
      }
    },

    closeMainDialog() {
      this.$emit("update:dialog", false);
      this.resetForm();
    },
  },
};
</script>
