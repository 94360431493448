var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-row',{staticClass:"mt-2"},[_c('v-col',{staticClass:"py-0",attrs:{"cols":"12"}},[(_vm.error)?_c('v-alert',{attrs:{"tile":"","type":"error"}},[_vm._v(_vm._s(_vm.error))]):_vm._e()],1),_c('v-col',{attrs:{"cols":"12","md":"2","sm":"6"}},[_c('v-text-field',{attrs:{"clearable":"","dense":"","hide-details":"","outlined":"","required":"","label":_vm.$t('datatable.searchLabel')},on:{"click:append-outer":function($event){return _vm.refresh()},"click:clear":function($event){return _vm.clearSearch()},"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;return _vm.refresh()}},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-spacer'),_c('v-col',{attrs:{"cols":"12","md":"2","align":"right"}},[_c('v-btn',{attrs:{"color":"primary","dark":"","rounded":"","small":"","text":""},on:{"click":function($event){return _vm.resetFilters()}}},[_vm._v(" "+_vm._s(_vm.$t("label.clearFilters"))+" ")])],1),_c('v-col',{attrs:{"cols":"12","md":"12"}},[_c('v-data-table',{staticClass:"row-pointer",attrs:{"expanded":_vm.expanded,"footer-props":{
        'disable-pagination': _vm.$apollo.queries.allTaskResultExtensions.loading,
        'items-per-page-options': [10, 25, 50, 75, 100],
      },"headers":_vm.headers,"items":_vm.allTaskResultExtensions.edges,"loading":_vm.$apollo.queries.allTaskResultExtensions.loading,"options":_vm.dataTableOptions,"server-items-length":_vm.allTaskResultExtensions.totalCount,"item-key":"node.id","single-expands":"","show-expand":""},on:{"update:expanded":function($event){_vm.expanded=$event},"update:options":function($event){_vm.dataTableOptions=$event},"click:row":(item, slot) => slot.expand(!slot.isExpanded)},scopedSlots:_vm._u([{key:`item.node.pk`,fn:function({ item }){return [_vm._v(" #TASK"+_vm._s(item.node.pk)+" ")]}},{key:`item.node.createdAt`,fn:function({ item }){return [_vm._v(" "+_vm._s(_vm._f("moment")(item.node.createdAt,"YYYY-MM-DD HH:mm"))+" ")]}},{key:`item.node.taskResult.status`,fn:function({ item }){return [_c('v-chip',{attrs:{"color":item.node.taskResult.status == 'STARTED'
              ? 'info'
              : item.node.taskResult.status == 'SUCCESS'
              ? 'success'
              : item.node.taskResult.status == 'FAILURE'
              ? 'error'
              : '',"small":""}},[(item.node.taskResult.status == 'STARTED')?_c('v-progress-circular',{staticClass:"ml-n1 mr-1",attrs:{"size":14,"width":1,"color":"white","indeterminate":""}}):_c('v-icon',{attrs:{"left":"","small":""}},[_vm._v(" "+_vm._s(item.node.taskResult.status == "SUCCESS" ? "check_circle" : item.node.taskResult.status == "FAILURE" ? "cancel" : "")+" ")]),_vm._v(" "+_vm._s(item.node.taskResult.status)+" ")],1)]}},{key:"item.data-table-expand",fn:function({ item, isExpanded }){return [(item.node.taskResult.status == 'SUCCESS')?_c('td',{staticClass:"text-start"},[_c('v-btn',{staticClass:"v-data-table__expand-icon",class:{
              'v-data-table__expand-icon--active': isExpanded,
            },attrs:{"icon":""}},[_c('v-icon',[_vm._v("expand_more")])],1)],1):_vm._e()]}},{key:"expanded-item",fn:function({ headers, item }){return [_c('td',{attrs:{"colspan":headers.length}},[_c('v-data-table',{staticClass:"row-pointer",attrs:{"headers":_vm.headersFile,"hide-default-footer":true,"hide-default-header":true,"items-per-page":100,"items":item.node.taskresultextensionfileSet.edges,"item-key":"item.node.id"},on:{"click:row":(item) => _vm.fetchTaskResultExtensionFile(item.node.id)},scopedSlots:_vm._u([{key:`item.action`,fn:function({ item }){return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c('v-icon',_vm._g({attrs:{"color":item.node.isDownloaded ? 'success' : 'error'}},on),[_vm._v(_vm._s(item.node.isDownloaded ? "check_circle" : "cancel"))])]}}],null,true)},[_c('span',[_vm._v(" "+_vm._s(_vm.$t("label.isDownloaded"))+" ")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c('v-btn',_vm._g({attrs:{"icon":"","color":"primary"}},on),[_c('v-icon',[_vm._v("file_download")])],1)]}}],null,true)},[_c('span',[_vm._v(" Download ")])])]}}],null,true)})],1)]}}],null,true)})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }